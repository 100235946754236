const data=[
    {
        id:1,
        nom: 'Lorem',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:2,
        nom: 'Lorems',
        date:'27 Decembre 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:3,
        nom: 'Loremd',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:4,
        nom: 'Loremf',
        date:'27 Juillets 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:5,
        nom: 'Lorem',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:9,
        nom: 'Lorem',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:6,
        nom: 'Lorem',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:7,
        nom: 'Lorem',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    },
    {
        id:8,
        nom: 'Lorem',
        date:'27 Juillet 2023',
        categorie:'Gestion / Comptabilité / Finance',
        contenu: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...'
    }
]

export default data;