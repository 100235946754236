import React, { useEffect, useState } from "react";
import "../css/detail_offre.css";
import NavBar from "../../layout/js/nav_bar.js";
import Footer from "../../layout/js/footer";
import dayjs from "dayjs";
import { Button, Modal } from "react-bootstrap";
import url from "../../url";

function MyVerticallyCenteredModal() {
  const [demandeur, setDemandeur] = useState("");
  const [offre, setOffre] = useState("");
  const [cv, setCv] = useState(null);
  const [lm, setLm] = useState(null);
  const [certification, setCertification] = useState(null);
  const Ajout = () => {
    const formdata = new FormData();
    formdata.append("demandeurIdDemandeur", demandeur);
    formdata.append("offreIdOffre", offre);
    formdata.append("lm", lm);
    formdata.append("cv", cv);
    formdata.append("certification", certification);

    url
      .post("/postule/", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => alert(res))
      .catch((error) => alert(error));
  };

  const [data, setData] = useState([]);
  const formating = (date) => {
    return dayjs(date).format("DD/MM/YYYY");
  };
  useEffect(() => {
    url
      .get("/offre/", {
        headers: {
          accessToken: sessionStorage.getItem("accesToken"),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const clickModal = (id_offre) => {
    setOffre(id_offre);
    handleShow();
  };
  return (
    <>
      <NavBar />

      <h6
        className="text-center"
        style={{
          marginTop: "6%",

          width: "70%",
          marginLeft: "15%",
        }}
        key="test"
      >
        {" "}
        Découvrez un éventail d'opportunités de stage dans des domaines variés
        proposées par divers entités. Que vous soyez intéressé par le
        développement web, le marketing, ou la finance ou d'autre, il y a une
        expérience de stage qui vous attend pour enrichir votre parcours
        professionnel.
      </h6>

      <div className="firstcontainer">
        {data.map((item) => (
          <div key={item.id_offre} className="card_item">
            <div className="card_inner" style={{ width: "300px", height: "350px" }}>
              <span className="text-center" style={{ marginTop: -10 }}>
                <b>{item.offreur.denomination}</b>
              </span>
              <p>
              <span style={{ color: '#0D6EFD', fontWeight: 'bold' }}>
                STAGIAIRE {item.poste} | {item.duree_stage} mois 
              </span><br />
                Vous êtes motivé, proactif et envie de vivre une expérience professionnelle enrichissante 
                dans le domaine <b> {item.domaine}</b>. <br />
                Date de clôture : {formating(item.depot_candidature)} <br />
                Dossier à fournir : {item.dossier_candidature}
              </p>

              <button
                className="btn align-items-center btn-primary "
                type="submit"
                onClick={() => clickModal(item.id_offre)}
              >
                Postuler
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Postuler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="cv">Deposez votre CV ici:</label>
            <input
              className="form-control"
              name="cv"
              onChange={(e) => {
                setCv(e.target.files[0]);
              }}
              type="file"
              placeholder="Insérer CV"
            />
            <br />
            <label htmlFor="lm">Deposez votre lettre de motivation ici:</label>
            <input
              className="form-control"
              name="lm"
              onChange={(e) => {
                setLm(e.target.files[0]);
              }}
              type="file"
              placeholder="Insérer LM"
            />
            <br />
            <label htmlFor="cer">Deposez votre certification ici:</label>
            <input
              className="form-control"
              name="certification"
              onChange={(e) => {
                setCertification(e.target.files[0]);
              }}
              placeholder="Insérer certification"
              type="file"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => Ajout()}
          >
            Postuler
          </button>
        </Modal.Footer>
      </Modal>
      <Footer className="footer" />
    </>
  );
}

const ModalDetailOffre = () => {
  const [modalShow, setModalShow] = React.useState(false);
  // const [isConnected, setIs_connected] = useState(true);

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ModalDetailOffre;
