import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const NavBarAdmin = () => {
  return (
    <>
      <nav className="">
        <Navbar className="fixed-top bg-" collapseOnSelect expand="lg">
          <Container className="container-fluide">
            <Navbar.Brand href="/">
              <img
                src="image/logo.png"
                className="ico"
                style={{ width: "79px", height: "50px" }}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link className="navlink" href="/admin">
                  <b>Offreurs</b>
                </Nav.Link>
                <Nav.Link className="navlink" href="/admin_offre">
                  <b>Offres</b>
                </Nav.Link>
                <Nav.Link className="navlink" href="/admin_opportunite">
                  <b>Opportunités</b>
                </Nav.Link>
                <Nav.Link className="navlink" href="/admin_demande">
                  <b>Demandes</b>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </nav>
    </>
  );
};
export default NavBarAdmin;
