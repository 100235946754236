import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/login.css";
import url from "../../url";
const LoginOffreur = () => {
  // Variables d'état pour l'email et le mot de passe
  const [email, setEmail] = useState("");
  const [motDePasse, setMotDePasse] = useState("");
  const navigate = useNavigate();
  // Gestionnaire d'événements pour mettre à jour l'état lorsque les champs changent
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMotDePasseChange = (e) => {
    setMotDePasse(e.target.value);
  };

  const login = () => {
    const data = { email: email, mot_de_passe: motDePasse };
    url
      .post("/offreur/login", data)
      .then((response) => {
        if (response.data.error) alert(response.data.error);
        sessionStorage.setItem("accessToken", response.data.accessToken);
        sessionStorage.setItem("id", response.data.id);
        navigate("/");
      })
      .catch((error) => {
        alert("mot de passe incorect");
        navigate("/login");
        console.log(error);
      });
  };
  // Gestionnaire d'événements pour la soumission du formulaire

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex_item flex_item1">
          <div className="d-flex flex-column">
            <img
              alt=""
              src="image/fond.png"
              style={{ width: "100%", height: "50%" }}
            ></img>
            <img src="image/logo.png" alt=""></img>
          </div>
        </div>
        <div className="flex_item">
          <div className="card_box">
            <h3 className="text-center ">Connecter-vous</h3>
            <br />
            <label>Email:</label>
            <input
              className="form-control"
              type="email"
              placeholder="Adresse email"
              value={email}
              onChange={handleEmailChange}
            />
            <br />
            <label>Mot de passe:</label>
            <input
              className="form-control"
              type="password"
              placeholder="Mot de passe"
              value={motDePasse}
              onChange={handleMotDePasseChange}
            />
            <br />
            <input type="checkbox" className="form-check-input" id="check" />
            <label htmlFor="check" className="">
              Se souvenir de moi
            </label>
            <br />
            <Link className="oubli" to="/reinitialisation ">
              Mot de passe oublié ?
            </Link>
            <br />
            <br />
            <Link>
              <button onClick={login} className="form-control btn btn-primary">
                Se connecter
              </button>
            </Link>
            <br></br>
            <p>Vous n'avez pas encore de compte ?</p>
            <Link to="/inscription">Inscription demandeur</Link>
            <br />
            <Link to="/ajout_offreur">Inscription offreur</Link>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginOffreur;
