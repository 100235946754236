import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Reinitialisation from "./login/js/reinitialisation";
import Recuperation from "./login/js/recuperation";
import Modification from "./login/js/modification";
import AjoutOffreur from "./offreur/js/ajout_offreur";
import Offreur from "./offreur/js/offreur";
import DetailOffre from "./offre_demande/js/detail_offre.jsx";
import Apropos from "./apropos/js/apropos";
import Acceuil from "./accueil/js/accueil";
import DetailForum from "./forum/js/details";
import Forum from "./forum/js/forum";
import Admin from "./Administrateur/js/Admin";
import AjoutOffre from "./offre_demande/js/ajout_offre";
import Inscription from "./inscription/js/Inscription";
import Opportunite from "./opportunite/js/opportunite";
import FileUpload from "./postuler/js/postuler";
import Ajout from "./offreur/js/ajout_offreur";
import Accueil from "./accueil/js/accueil";
import OffreDemande from "./offre_demande/js/Offre_demande";
import InscriptionGlobal from "./inscription/js/inscription_global";
import Login from "./login/js/login";
import AjoutDemande from "./offre_demande/js/ajout_demande";
import DetailDemande from "./offre_demande/js/detail_demande";
import Postuler from "./postuler/js/postuler";
import DetailOpportunite from "./opportunite/js/detail_opportunite";
import Profil from "./profil/jsx/profil";
import Documentation from "./documentation/js/documentation";
import AdminDemande from "./Administrateur/js/admin_demande.jsx";
import AdminOffre from "./Administrateur/js/admin_offre";
import AdminOpportunite from "./Administrateur/js/admin_opportunite";
import AdminPostuler from "./Administrateur/js/admin_postuler";
import ProfilOffreur from "./profil/jsx/profil_offreur";
import LoginOffreur from "./login/js/login_offreur";
import AccueilOffreur from "./accueil/js/acceui_offreur";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/accueil-offreur" element={<AccueilOffreur />}></Route>
          <Route path="/" element={<Acceuil />}></Route>
          <Route path="/recuperation" element={<Recuperation />}></Route>
          <Route path="/modification" element={<Modification />}></Route>
          <Route path="/admin_postuler" element={<AdminPostuler />}></Route>
          <Route path="/login-offreur" element={<LoginOffreur />}></Route>
          <Route path="/profil-offreur" element={<ProfilOffreur />}></Route>

          <Route
            path="/reinitialisation"
            element={<Reinitialisation />}
          ></Route>
          <Route path="/ajout_offreur" element={<AjoutOffreur />}></Route>
          <Route path="/offre" element={<DetailOffre />}></Route>
          <Route path="/detail_forum" element={<DetailForum />}></Route>
          <Route path="/forum" element={<Forum />}></Route>
          <Route path="/ajout_offre" element={<AjoutOffre />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/inscription" element={<Inscription />}></Route>
          <Route path="/ajout_offreur" element={<Ajout />}></Route>
          <Route path="/liste_offreur" element={<Offreur />}></Route>
          <Route path="/upload" element={<FileUpload />}></Route>
          <Route path="/acceuil" element={<Accueil />}></Route>
          <Route path="/offre_demande" element={<OffreDemande />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/ajout_demande" element={<AjoutDemande />}></Route>
          <Route path="/demande" element={<DetailDemande />}></Route>
          <Route path="/admin_demande" element={<AdminDemande />}></Route>
          <Route path="/admin_offre" element={<AdminOffre />}></Route>
          <Route
            path="/admin_opportunite"
            element={<AdminOpportunite />}
          ></Route>
          <Route
            path="/Inscription_global"
            element={<InscriptionGlobal />}
          ></Route>
          <Route path="/opportunite" element={<Opportunite />}></Route>
          <Route path="/postuler" element={<Postuler />}></Route>
          <Route path="/documentation" element={<Documentation />}></Route>
          <Route path="/apropos" element={<Apropos />}></Route>
          <Route
            path="/liste_opportunite"
            element={<DetailOpportunite />}
          ></Route>
          <Route path="/profil" element={<Profil />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
