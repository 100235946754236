import React, { useState } from "react";
import url from "../../url";
import { useNavigate, Link } from "react-router-dom";

function Ajout() {
  const [values, setValues] = useState({
    denomination: "",
    entite: "",
    secteur: "",
    secteur_activite: "",
    contact: "",
    site: "",
    localisation: "",
    email: "",
    mot_de_passe: "",
    mot_de_passe1: "",
  });
  const navigate = useNavigate();
  const AjoutOffreur = () => {
    if (
      values.denomination !== "" ||
      values.entite !== "" ||
      values.secteur !== "" ||
      values.secteur_activite !== "" ||
      values.contact !== "" ||
      values.site !== "" ||
      values.localisation !== "" ||
      values.email !== "" ||
      values.mot_de_passe !== "" ||
      values.mot_de_passe1 !== ""
    ) {
      url
        .post("/offreur", {
          denomination: values.denomination,
          entite: values.entite,
          secteur: values.secteur,
          secteur_activite: values.secteur_activite,
          contact: values.contact,
          site: values.site,
          localisation: values.localisation,
          email: values.email,
          mot_de_passe: values.mot_de_passe,
          mot_de_passe1: values.mot_de_passe1,
        })

        .then((response) => {
          alert("Ajout avec succés");
          navigate("/login");
        })
        .catch((error) => {
          console.log(error);
          // Gérer l'erreur ici
        });
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   this.setState({
  //     [name]: e.target.value,
  //   });
  // };
  return (
    <div className="container">
      <div className="ajouter">
        <form onSubmit="">
          <div className="container container_inscription">
            <div className="container">
              {/* <div className="titre_inscription">
                <h3 className="text-center">
                  Inscription pour les offreurs de stage professionnel
                </h3>
              </div> */}

              <div className="information_inscription">
                <div className="information_titre_identite form-control">
                  Inscription pour les offreurs de stage professionnel
                </div>
                <div className="information_personnel row">
                  <div className="information_personnel_page1 col-md-6">
                    <div className="div_input">
                      <label>Dénomination:</label> <br />
                      <input
                        type="text"
                        value={values.denomination}
                        onChange={handleInput}
                        name="denomination"
                        className="input form-control"
                        required
                        placeholder="dénomination"
                      />
                    </div>
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="categorie">
                        Catégorie de l'entité:
                      </label>
                      <select
                        className="input form-control"
                        name="entite"
                        value={values.entite}
                        onChange={handleInput}
                        required
                      >
                        <option value="">Sélectionner une catégorie</option>
                        <option value="Entreprise">Entreprise</option>
                        <option value="Association">Association</option>
                        <option value="Coopérative">Coopérative</option>
                        <option value="Organisation professionnelle">
                          Organisation professionnelle
                        </option>
                        <option value="Agence de projet de développement">
                          Agence de projet de développement
                        </option>
                        <option value="Organisme international">
                          Organisme international
                        </option>
                        <option value="Institution publique">
                          Institution publique
                        </option>
                      </select>
                    </div>
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="activité">
                        Sécteur:
                      </label>
                      <input
                        type="text"
                        className="input form-control"
                        placeholder="Exemple: Technologie"
                        name="secteur"
                        value={values.secteur}
                        onChange={handleInput}
                        required
                      />
                    </div>
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="numero">
                        Numéro de téléphone:
                      </label>
                      <input
                        className="input form-control"
                        type="text"
                        name="contact"
                        value={values.contact}
                        onChange={handleInput}
                        placeholder="Votre numéro de téléphone"
                        required
                      />
                    </div>
                    <div className="div_input">
                      <label htmlFor="secteur_activite">Activité:</label>
                      <input
                        className="input form-control"
                        type="text"
                        name="secteur_activite"
                        value={values.secteur_activite}
                        onChange={handleInput}
                        placeholder="Exemple: Developpement logiciel"
                        required
                      />
                    </div>
                  </div>
                  <div className="information_page2 col-md-6">
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="site">
                        Site web:
                      </label>
                      <input
                        className="input form-control"
                        type="text"
                        name="site"
                        value={values.site}
                        onChange={handleInput}
                        required
                        placeholder="Votre site web"
                      />
                    </div>
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="localisation">
                        Localisation géographique:
                      </label>
                      <input
                        className="form-control input"
                        type="text"
                        name="localisation"
                        value={values.localisation}
                        onChange={handleInput}
                        placeholder="Votre adresse"
                        required
                      />
                    </div>

                    <div className="div_input">
                      <label className="label_ajout" htmlFor="email">
                        Votre email:
                      </label>
                      <input
                        className="input form-control"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleInput}
                        required
                        placeholder="Veuillez confirmer votre mot de passe"
                      />
                    </div>
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="email">
                        Mot de passe
                      </label>
                      <input
                        className="input form-control"
                        type="password"
                        name="mot_de_passe"
                        value={values.mot_de_passe}
                        onChange={handleInput}
                        placeholder="Votre mot de passe"
                        required
                      />
                    </div>
                    <div className="div_input">
                      <label className="label_ajout" htmlFor="email">
                        Confirmation mot de passe
                      </label>
                      <input
                        className="input form-control"
                        type="password"
                        name="mot_de_passe1"
                        value={values.mot_de_passe1}
                        onChange={handleInput}
                        required
                        placeholder="Veuillez confirmer votre mot de passe"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_pagination_inscription">
                <div>
                  <button
                    type="submit"
                    onClick={() => AjoutOffreur()}
                    className="btn btn-primary"
                  >
                    S'inscrire
                  </button>
                  <Link to="/login" className="btn btn-outline-danger">
                    Annuler
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Ajout;
