import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/dashboard.css";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";
import url from "../../url";
function ContenuOffre() {
  const column = [
    // {
    //   name: "id",
    //   selector: (row) => row.id_offre,
    //   sortable: true,
    // },
    // {
    //   name: "Id offreur",
    //   selector: (row) => row.offreurIdOffreur,
    //   sortable: true,
    // },
    {
      name: "domaine",
      selector: (row) => row.domaine,
      sortable: true,
    },
    {
      name: "Poste",
      selector: (row) => row.poste,
      sortable: true,
    },
    {
      name: "Lieu de stage",
      selector: (row) => row.lieu_stage,
      sortable: true,
    },
    {
      name: "Duree stage",
      selector: (row) => row.duree_stage,
      // sortable:true
    },
    {
      name: "Profil réquis",
      selector: (row) => row.profil_demande,
      // sortable:true
    },
    {
      name: "Condition",
      selector: (row) => row.condition,
      // sortable:true
    },
    {
      name: "Dossier candidature",
      selector: (row) => row.dossier_candidature,
      // sortable:true
    },
    {
      name: "Date clôture",
      selector: (row) => row.depot_candidature,
      // sortable:true
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            clickModal(
              // row.id_offre,
              // row.offreurIdOffreur,
              row.domaine,
              row.poste,
              row.lieu_stage,
              row.duree_stage,
              row.profil_demande,
              row.condition,
              row.dossier_candidature,
              row.depot_candidature
            )
          }
        >
          <AiTwotoneEdit />
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button type="button" className="btn btn-danger">
          <MdDeleteOutline onClick={() => handleDelete(row.id_offre)} />
        </button>
      ),
    },
  ];

  const [values, setValues] = useState({
    // id_offre: "",
    // offreurIdOffreur: "",
    domaine: "",
    poste: "",
    lieu_stage: "",
    duree_stage: "",
    profil_demande: "",
    condition: "",
    dossier_candidature: "",
    depot_candidature: "",
  });
  const [modal_modifie_offreur, setModal_modifie_offreur] = useState(false);
  const [v_lieu_stage, setV_lieu_stage] = useState("");
  const [v_domaine, setV_domaine] = useState("");
  const [v_poste, setV_poste] = useState("");
  const [v_duree_stage, setV_duree_stage] = useState("");
  const [v_offreurIdOffreur, setV_offreurIdOffreur] = useState("");
  const [v_profil_demande, setv_profil_demande] = useState("");
  const [v_condition, setV_condition] = useState("");
  const [v_depot_candidature, setV_depot_candidature] = useState("");
  const [v_id_offre, setV_id_offre] = useState("");
  const [v_dossier_candidature, setV_dossier_candidature] = useState("");
  const handleDelete = (index) => {
    const confirm = window.confirm("voulez vous supprimer ");
    if (confirm) {
      url
        .delete("/offre/" + index)
        .then((response) => alert("Suppression réussi!"))
        .catch((error) => alert(error));
    }
  };
  const clickModal = (
    // id_offre,
    // offreurIdOffreur,
    domaine,
    poste,
    lieu_stage,
    duree_stage,
    profil_demande,
    condition,
    dossier_candidature,
    depot_candidature
  ) => {
    setModal_modifie_offreur(!modal_modifie_offreur);
    // setV_id_offre(id_offre);
    // setV_offreurIdOffreur(offreurIdOffreur);
    setV_domaine(domaine);
    setV_poste(poste);
    setV_lieu_stage(lieu_stage);
    setV_duree_stage(duree_stage);
    setv_profil_demande(profil_demande);
    setV_condition(condition);
    setV_dossier_candidature(dossier_candidature);
    setV_depot_candidature(depot_candidature);
    setEdit(true);
  };

  const handleAdd = () => {
    if (
      // values.id_offre !== "" ||
      // values.offreurIdOffreur !== "" ||
      values.domaine !== "" ||
      values.poste !== "" ||
      values.lieu_stage !== "" ||
      values.duree_stage !== "" ||
      values.profil_demande !== "" ||
      values.condition !== "" ||
      values.dossier_candidature !== "" ||
      values.depot_candidature !== ""
    ) {
      url
        .post("/offre/", {
          // id_offre: values.id_offre,
          // offreurIdOffreur: values.offreurIdOffreur,
          domaine: values.domaine,
          poste: values.poste,
          lieu_stage: values.lieu_stage,
          duree_stage: values.duree_stage,
          profil_demande: values.profil_demande,
          condition: values.condition,
          dossier_candidature: values.dossier_candidature,
          depot_candidature: values.depot_candidature,
        })
        .then((res) => {
          alert("Ajout avec succés");
        })
        .catch((err) => console.log(err));
    }
  };
  const handleEdit = (id) => {
    if (
      // v_id_offre !== "" ||
      // v_offreurIdOffreur !== "" ||
      v_domaine !== "" ||
      v_poste !== "" ||
      v_lieu_stage !== "" ||
      v_duree_stage !== "" ||
      v_profil_demande !== "" ||
      v_condition !== "" ||
      v_dossier_candidature !== "" ||
      v_depot_candidature !== ""
    ) {
      url
        .put("/offre/" + id, {
          // id_offre: v_id_offre,
          // offreurIdOffreur: v_offreurIdOffreur,
          domaine: v_domaine,
          poste: v_poste,
          lieu_stage: v_lieu_stage,
          duree_stage: v_duree_stage,
          profil_demande: v_profil_demande,
          condition: v_condition,
          dossier_candidature: v_dossier_candidature,
          depot_candidature: v_depot_candidature,
        })
        .then((res) => {
          alert("Modification avec succés");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const fet_data = async () => {
      url
        .get("/offre/", {
          headers: {
            accessToken: sessionStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          setRecords(res.data);
          setrecordFilter(res.data);
        })
        .catch((err) => console.log(err));
    };
    fet_data();
  }, []);

  // const handleModifier = (idModif) => {
  //   axios
  //     .put("http://localhost:100/offreur/" + idModif, {
  //       headers: {
  //         accessToken: sessionStorage.getItem("accessToken"),
  //       },
  //     })
  //     .then((response) => {
  //       alert("succés");
  //     })
  //     .catch((error) => console.log(error));
  // };
  const [records, setRecords] = useState([]);
  const [recordfilter, setrecordFilter] = useState([]);
  const handlefilter = (e) => {
    const new_data = recordfilter.filter((row) =>
      row.entite.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRecords(new_data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [edit, setEdit] = useState(false);
  const handleCloseEdit = () => setEdit(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Gestion des offres</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={column}
          data={records}
          // selectableRows
          fixedHeader
          pagination
        ></DataTable>
      </div>
      <Modal show={edit} onHide={handleCloseEdit}>
        <Modal.Header style={{ background: "#FFFF" }} closeButton>
          Modifier offre
        </Modal.Header>
        <Modal.Body>
          {/* <div class="">
            <input type="text" value={v_id_offre} />
            <label>id offreur :</label> <br />
            <input
              type="text"
              onChange={(e) => setV_offreurIdOffreur(e.target.value)}
              name="v_offreurIdOffreur"
              value={v_offreurIdOffreur}
              className="form-control"
              required
              placeholder="fr"
            />
          </div> */}
          <div class="">
            <label>Domaine:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_domaine(e.target.value)}
              name="v_domaine"
              value={v_domaine}
              className="form-control"
              required
              placeholder="Sécteur d'activité"
            />
          </div>
          <div class="">
            <label>Poste:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_poste(e.target.value)}
              name="v_poste"
              value={v_poste}
              className="form-control"
              required
              placeholder="Poste"
            />
          </div>
          <div class="">
            <label>Lieu stage:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_lieu_stage(e.target.value)}
              name="v_lieu_stage"
              value={v_lieu_stage}
              className="form-control"
              required
              placeholder="Sécteur"
            />
          </div>
          <div class="">
            <label>Durée stage:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_duree_stage(e.target.value)}
              name="v_duree_stage"
              className="form-control"
              required
              value={v_duree_stage}
            />
          </div>
          <div class="">
            <label>Profil demande:</label> <br />
            <input
              type="email"
              onChange={(e) => setv_profil_demande(e.target.value)}
              name="email"
              className="form-control"
              value={v_profil_demande}
              required
              placeholder="Email"
            />
          </div>
          <div class="">
            <label>Condition:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_condition(e.target.value)}
              name="v_condition"
              className="form-control"
              value={v_condition}
              required
              placeholder="Condition"
            />
          </div>
          <div class="">
            <label>Dossier candidature:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_dossier_candidature(e.target.value)}
              name="v_dossier_candidature"
              className="form-control"
              value={v_dossier_candidature}
              required
              placeholder="dossier"
            />
          </div>
          <div class="">
            <label>Date candidature:</label> <br />
            <input
              type="date"
              onChange={(e) => setV_depot_candidature(e.target.value)}
              name="v_depot_candidature"
              className="form-control"
              value={v_depot_candidature}
              required
              placeholder="Localisation"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleEdit(v_id_offre)}
          >
            Modifier
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ background: "#" }} closeButton>
          Ajout offre
        </Modal.Header>
        <Modal.Body>
          {/* <div class="">
            <label>offre id:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="id_offre"
              value={values.id_offre}
              className="form-control"
              required
              placeholder=""
            />
          </div>
          <div class="">
            <label>offreur id:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="offreurIdOffreur"
              value={values.offreurIdOffreur}
              className="form-control"
              required
              placeholder=""
            />
          </div> */}
          <div class="">
            <label>Domaine:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="domaine"
              value={values.domaine}
              className="form-control"
              required
              placeholder="domaine"
            />
          </div>
          <div class="">
            <label>Poste:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="poste"
              value={values.poste}
              className="form-control"
              required
              placeholder="poste"
            />
          </div>
          <div class="">
            <label>Lieu de stage:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="lieu_stage"
              value={values.lieu_stage}
              className="form-control"
              required
              placeholder="lieu de stage"
            />
          </div>
          <div class="">
            <label>Durée de stage:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="duree_stage"
              className="form-control"
              required
              value={values.duree_stage}
            />
          </div>
          <div class="">
            <label>Profil demandé:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="profil_demande"
              className="form-control"
              value={values.profil_demande}
              required
              placeholder="Profil demandé"
            />
          </div>
          <div class="">
            <label>Condition:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="condition"
              className="form-control"
              value={values.condition}
              required
              placeholder="Condition de stage"
            />
          </div>
          <div class="">
            <label>Dossier à fournir:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="dossier_candidature"
              className="form-control"
              value={values.dossier_candidature}
              required
              placeholder="Localisation"
            />
          </div>
          <div class="">
            <label>Date de clôture:</label> <br />
            <input
              type="date"
              onChange={handleInput}
              name="depot_candidature"
              className="form-control"
              value={values.depot_candidature}
              required
              placeholder=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleAdd()}
          >
            Ajouter
          </button>
        </Modal.Footer>
      </Modal>
      <button className="btn btn-success" onClick={(e) => setShow(true)}>
        +
      </button>
    </div>
  );
}

export default ContenuOffre;
