const data_pub = [
  {
    id: 1,
    photo: "../image/1.jpg",
    contenu: "",
    detail: "organisation non gouverné",
  },
  {
    id: 2,
    photo: "../image/2.jpg",
    contenu: "",
    detail: "organisation non gouverné",
  },
  {
    id: 2,
    photo: "../image/3.jpg",
    contenu: "",
    detail: "organisation non gouverné",
  },
];

export default data_pub;
