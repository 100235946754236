import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/inscription.css";
import url from "../../url";
import Validation_form_inscription from "./Validation_form_inscription";
import { Navigate, Link } from "react-router-dom";

function Inscription() {
  const [values, setValues] = useState({
    photo: "",
    nom: "",
    email: "",
    date_naissance: "",
    lieu_naissance: "",
    age: "",
    sexe: "",
    contact: "",
    mot_de_passe: "",
    mot_de_passe1: "",
    denomination: "",
    entite: "",
    secteur: "",
    secteur_activite: "",
    contactoffreur: "",
    site: "",
    localisation: "",
    emailoffreur: "",
    mot_de_passeoffreur: "",
    mot_de_passeoffreur1: "",
  });
  const [errors, setErrors] = useState({});
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  function handleValidation(e) {
    e.preventDefault();
    setErrors(Validation_form_inscription(values));
  }
  // const AjoutOffreur = () => {
  //   if (
  //     values.denomination !== "" ||
  //     values.entite !== "" ||
  //     values.secteur !== "" ||
  //     values.contactoffreur !== "" ||
  //     values.secteur_activite !== "" ||
  //     values.site !== "" ||
  //     values.localisation !== "" ||
  //     values.emailoffreur !== "" ||
  //     values.mot_de_passeoffreur !== "" ||
  //     values.mot_de_passeoffreur1 !== " "
  //   ) {
  //     url
  //       .post("/offreur", {
  //         denomination: values.denomination,
  //         entite: values.entite,
  //         secteur: values.secteur,
  //         secteur_activite: values.secteur_activite,
  //         contact: values.contact,
  //         site: values.site,
  //         localisation: values.localisation,
  //         email: values.email,
  //         mot_de_passe: values.mot_de_passe,
  //         mot_de_passe1: values.mot_de_passe1,
  //       })

  //       .then((response) => {
  //         // Traiter la réponse ici
  //         alert("Inscription avec succés");
  //         Navigate("/login");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         // Gérer l'erreur ici
  //       });
  //   }
  // };
  const AjoutInscription = () => {
    if (
      values.nom !== "" ||
      values.date_naissance !== "" ||
      values.lieu_naissance !== "" ||
      values.contact !== "" ||
      values.email !== "" ||
      values.mot_de_passe !== "" ||
      values.age !== "" ||
      values.mot_de_passe1 !== ""
    ) {
      url
        .post("/demandeur", {
          photo: values.photo,
          nom: values.nom,
          email: values.email,
          date_naissance: values.date_naissance,
          lieu_naissance: values.lieu_naissance,
          age: values.age,
          sexe: values.sexe,
          contact: values.contact,
          mot_de_passe: values.mot_de_passe,
          mot_de_passe1: values.mot_de_passe1,
        })

        .then((response) => {
          // Traiter la réponse ici
          alert("Inscription avec succés");
        })
        .catch((error) => {
          console.log(error);
          // Gérer l'erreur ici
        });
    }
  };

  return (
    <>
      <form onSubmit={handleValidation}>
        <div className="container container_inscription">
          <div className="container">
            {/* <div className="titre_inscription">
              <h3 className="text-center">
                Inscription pour les demandeurs de stage professionnel
              </h3>
            </div> */}

            <div className="information_inscription">
              <div className="information_titre_identite form-control">
                Inscription pour les demandeurs de stage professionnel
              </div>
              <div className="information_personnel row">
                <div className="information_personnel_page1 col-md-6">
                  <div className="div_input">
                    <label>Nom et prénom:</label> <br />
                    <input
                      type="text"
                      onChange={handleInput}
                      value={values.nom}
                      name="nom"
                      className="input form-control"
                      required
                      placeholder="Nom et prénom"
                    />
                  </div>
                  <div className="div_input">
                    <label>Date de naissance:</label> <br />
                    <input
                      type="date"
                      onChange={handleInput}
                      value={values.date_naissance}
                      name="date_naissance"
                      className="input form-control"
                      required
                      placeholder="Date de naissance"
                    />
                    {errors.date_naiss && (
                      <p className="p_errors">{errors.date_naiss}</p>
                    )}
                  </div>
                  <div className="div_input">
                    <label>Lieu de naissance:</label> <br />
                    <input
                      type="text"
                      onChange={handleInput}
                      name="lieu_naissance"
                      value={values.lieu_naissance}
                      className="input form-control"
                      required
                      placeholder="Lieu/Region de naissance"
                    />
                    {errors.date_naiss && (
                      <p className="p_errors">{errors.date_naiss}</p>
                    )}
                  </div>
                  <div className="div_input">
                    <label>Age:</label> <br />
                    <input
                      type="text"
                      onChange={handleInput}
                      value={values.age}
                      name="age"
                      className="input form-control"
                      required
                      placeholder="Age"
                    />
                    {errors.contact && (
                      <p className="p_errors">{errors.contact}</p>
                    )}
                  </div>
                  <div className="input_radio div">
                    <label className="label_sexe">Sexe:</label>
                    <span className="input_radio_group">
                      <input
                        name="sexe"
                        onChange={handleInput}
                        value="homme"
                        id="homme"
                        required
                        type="radio"
                      />
                      <label htmlFor="homme">Homme</label>
                      <input
                        name="sexe"
                        value="femme"
                        onChange={handleInput}
                        id="femme"
                        required
                        type="radio"
                      />
                      <label htmlFor="femme">Femme</label>
                    </span>
                  </div>
                </div>
                <div className="information_page2 col-md-6">
                  <div className="div_input">
                    <label>Contact rapide :</label> <br />
                    <input
                      type="text"
                      onChange={handleInput}
                      value={values.contact}
                      name="contact"
                      className="input form-control"
                      required
                      placeholder="Contact"
                    />
                  </div>
                  <div className="div_input">
                    <label>Email :</label> <br />
                    <input
                      type="text"
                      onChange={handleInput}
                      name="email"
                      value={values.email}
                      className="input form-control"
                      required
                      placeholder="Email"
                    />
                  </div>
                  <div className="div_input">
                    <label>Mot de passe :</label> <br />
                    <input
                      type="password"
                      onChange={handleInput}
                      value={values.mot_de_passe}
                      name="mot_de_passe"
                      className="input form-control"
                      required
                      placeholder="Mot de passe"
                    />
                    {errors.mot_de_passe && (
                      <p className="p_errors">{errors.mot_de_passe}</p>
                    )}
                  </div>
                  <div className="div_input">
                    <label>Confirmer le mot de passe :</label> <br />
                    <input
                      type="password"
                      value={values.confirm_mdp}
                      onChange={handleInput}
                      name="mot_de_passe1"
                      className="input form-control"
                      required
                      placeholder="Confirmer le mot de passe"
                    />
                    {errors.confirm_mdp && (
                      <p className="p_errors">{errors.confirm_mdp}</p>
                    )}
                  </div>
                  <div className="div_input">
                    <label>Photo :</label> <br />
                    <input
                      type="file"
                      value={values.photo}
                      onChange={handleInput}
                      name="photo"
                      className="input form-control"
                      placeholder="Confirmer le mot de passe"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_pagination_inscription">
              <div>
                <button
                  type="submit"
                  onClick={AjoutInscription}
                  className="btn btn-primary"
                >
                  S'inscrire
                </button>
                <Link to="/login" className="btn btn-outline-danger">
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Inscription;
