import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "../css/footer.css";
import { BsFacebook } from "react-icons/bs";
import { BiLogoLinkedinSquare } from "react-icons/bi";

const Footer = () => {
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="d-flex align-items-center">
          <span>Notre réseau sociaux:</span>
          <BsFacebook
            size={23}
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              color: "#1877F2",
            }}
          />
          <BiLogoLinkedinSquare
            size={27}
            style={{ marginRight: "10px", color: "#0A66C2" }}
          />
        </div>
      </section>
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="6">
              <h6 className="text-uppercase fw-bold mb-4">
                <img
                  src="image/logo.png"
                  className="ico"
                  style={{ width: "79px", height: "50px" }}
                  alt=""
                />
                {/* Traikefa2ASA */}
              </h6>
              <p>
                Découvrez Traikefa'2asa, la plateforme novatrice et axée sur
                l'employabilité, développée en partenariat avec Entreprendre à
                Madagascar (EAM) et en collaboration avec la société HAISOA.
                Notre mission est de connecter les jeunes talents avec des
                opportunités de stage enrichissantes pour les aider à construire
                un avenir professionnel prometteur à Madagascar.
              </p>
            </MDBCol>
            <MDBCol md="6">
              <h6 className="text-uppercase fw-bold mb-4">Abonnez-vous</h6>
              <p>
                Abonnez-vous dès maintenant à Traikefa'2asa, la plateforme de
                référence dédiée à l'employabilité et aux stages professionnels
                enrichissants.
              </p>
              <div className="d-flex flex-row">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      @
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Adresse email"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <button className="btn btn-dark" style={{ height: 40 }}>
                  Abonnez
                </button>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2023 Copyright:
        <a className="text-reset fw-bold" href="https://haisoa.com/">
          haisoa.com
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
