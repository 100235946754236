import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/accueil.css";
import Publicite from "./Publicite";
import Oportinute from "./oportinute";
import Partenariat from "./Partenariat";
import NavBar from "../../layout/js/nav_bar";
import Footer from "../../layout/js/footer";

function accueil() {
  return (
    <>
      <NavBar />
      <Publicite />

      <div className="acceuil_evenement">
        <div className="div_titre_evenement">
          <h2 class="titre_evenement">Opportunité</h2>
        </div>
        <Oportinute />
      </div>
      <div className="acceuil_formation">
        <div className="div_titre_evenement">
          <h2 class="titre_evenement">Partenaires</h2>
        </div>
        <Partenariat />
      </div>
      <Footer />
    </>
  );
}

export default accueil;
