function Validation_form_inscription(values) {
  let error = {};
  const email_gex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mdp_gex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zAZ0-9]{8,}$/;

  if (values.nom === "") {
    error.nom = "Veillez remplire ce champ";
  }
  if (values.prenom === "") {
    error.prenom = "Veillez remplire ce champ";
  }
  if (values.date_naiss === "") {
    error.date_naiss = "Veillez remplire ce champ";
  }
  if (values.lieu_naiss === "") {
    error.lieu_naiss = "Veillez remplire ce champ";
  }
  if (values.email === "") {
    error.email = "Veillez remplire ce champ";
  }
  // if(values.contact===''){
  //     error.contact = "Veillez remplire ce champ"
  // }
  if (values.adresse === "") {
    error.adresse = "Veuillez remplir ce champ";
  }
  if (values.specialisation === "") {
    error.specialisation = "Veuillez remplir ce champ";
  }
  if (values.diplome === "") {
    error.diplome = "Veuillez remplir ce champ";
  }
  if (values.certificat === "") {
    error.certificat = "Veuillez remplir ce champ";
  }
  if (values.certificat === "") {
    error.certificat = "Veuillez remplir ce champ";
  }
  if (values.filiere_module === "") {
    error.filiere_module = "Veuillez remplir ce champ";
  }
  if (values.confirm_mdp !== values.mot_de_passe) {
    error.confirm_mdp = "";
  }
  if (!email_gex.test(values.email)) {
    error.email = "Le mail n'est pas valide";
  }
  if (!mdp_gex.test(values.mot_de_passe)) {
    error.mot_de_passe = "Veuillez verifier votre mot de passe";
  }

  return error;
}

export default Validation_form_inscription;
