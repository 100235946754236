import React, { useState, useEffect } from "react";
import "../css/detail_demande.css";
import NavBar from "../../layout/js/nav_bar";
import Footer from "../../layout/js/footer";
import { useNavigate } from "react-router-dom";
import url from "../../url";
const Demande = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    url
      .get("/demande", {
        headers: {
          accessToken: sessionStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <h6
        className="text-center"
        style={{
          marginTop: "6%",

          width: "70%",
          marginLeft: "15%",
        }}
      >
        {" "}
        Nous accueillons actuellement plusieurs demande de stage, chacun
        apportant une expertise unique dans des domaines variés. Si vous
        cherchez des stagiaires hautement qualifiés pour renforcer votre équipe,
        consultez nos candidats prêts à contribuer à votre succès.
      </h6>
      <div className="firstcontainer">
        {data.map((item) => (
          <div key={item.id_demande} className="card_item">
            <div className="card_inner">
              {/* <h6>Domaine: {item.domaine}</h6>
              <br /> */}
              <p className="text-center">
                À la recherche d'un stage en {item.domaine} pour une durée de {item.duree} mois, 
                dans le secteur {item.en_secteur}.<br />
                <center>----------</center>
                Diplome: {item.diplome} en {item.filiere} <br />
                Lieu de préference: {item.lieu}</p>
            </div>
          </div>
        ))}
      </div>
      <button
        className="btn btn-success"
        onClick={() => navigate("/ajout_demande")}
      >
        Ajouter un demande
      </button>
      <br />
      <br />
      <Footer className="footer" />
    </>
  );
};
export default Demande;
