import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/dashboard.css";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import url from "../../url";

function ContenuOffre() {
  const column = [
    // {
    //   name: "id_postule",
    //   selector: (row) => row.id_postule,
    //   sortable: true,
    // },
    // {
    //   name: "Demandeur",
    //   selector: (row) => row.demandeurIdDemandeur,
    //   sortable: true,
    // },
    {
      name: "Offre",
      selector: (row) => row.offreIdOffre,
      sortable: true,
    },
    {
      name: "Lettre de motivation",
      selector: (row) => {
        <a href={row.lm} download>
          {row.lm}
        </a>;
      },
      sortable: true,
    },
    {
      name: "Durée de stage",
      selector: (row) => row.duree,
      sortable: true,
    },
    {
      name: "Filière",
      selector: (row) => row.filiere,
      // sortable:true
    },
    {
      name: "Spécialisation",
      selector: (row) => row.specialisation,
      // sortable:true
    },
    {
      name: "Diplôme",
      selector: (row) => row.diplome,
      // sortable:true
    },
    {
      name: "Date d'obtention",
      selector: (row) => row.date_obtention,
      // sortable:true
    },
    {
      name: "Activite",
      selector: (row) => row.en_activite,
      // sortable:true
    },
    {
      name: "Sécteur d'activite",
      selector: (row) => row.en_secteur,
      // sortable:true
    },
    {
      name: "Domaine",
      selector: (row) => row.domaine,
      // sortable:true
    },
    {
      name: "Lieu",
      selector: (row) => row.lieu,
      // sortable:true
    },
    {
      name: "établissement d'accueil",
      selector: (row) => row.etab_accueil,
      // sortable:true
    },
    {
      name: "CV",
      selector: (row) => row.pdf,
      // sortable:true
    },
    {
      name: "domaine",
      cell: (row) => (
        <button type="button" className="btn btn-primary">
          <AiTwotoneEdit />
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button type="button" className="btn btn-danger">
          <MdDeleteOutline />
        </button>
      ),
    },
  ];

  const [records, setRecords] = useState([]);
  useEffect(() => {
    const fet_data = async () => {
      url
        .get("/demande", {
          headers: {
            accessToken: sessionStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          setRecords(res.data);
        })
        .catch((err) => console.log(err));
    };
    fet_data();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Gestion des demandes</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={column}
          data={records}
          // selectableRows
          fixedHeader
          pagination
        ></DataTable>
      </div>
    </div>
  );
}

export default ContenuOffre;
