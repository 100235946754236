import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/accueil.css";
import Publicite from "./Publicite";
import Oportinute from "./oportinute";
import Partenariat from "./Partenariat";
import Footer from "../../layout/js/footer";
import NavBarOffreur from "../../layout/js/navbar_offreur";

function AccueilOffreur() {
  return (
    <>
      <NavBarOffreur />
      <Publicite />

      <div className="acceuil_evenement">
        <div className="div_titre_evenement">
          <h2 class="titre_evenement">Opportunité</h2>
        </div>
        <Oportinute />
      </div>
      <div className="acceuil_formation">
        <div className="div_titre_evenement">
          <h2 class="titre_evenement">Partenaire</h2>
        </div>
        <Partenariat />
      </div>
      <Footer />
    </>
  );
}

export default AccueilOffreur;
