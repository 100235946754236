import React, { useState } from "react";
import NavBar from "../../layout/js/nav_bar.js";
import "../css/ajout_demande.css";
import Footer from "../../layout/js/footer.js";
import url from "../../url.js";

const Ajout_demande = () => {
  const [values, setValues] = useState({
    demandeurid_demandeur: "",
    domaine: "",
    duree: "",
    specialisation: "",
    etab_accueil: "",
    lieu: "",
    date_obtention: "",
    diplome: "",
    etablissement: "",
    en_secteur: "",
    // en_activite: "",
    filiere: "",
    objectif: "",
    pdf: "",
    date_debut: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const AddDemande = () => {
    url
      .post("/demande", {
        domaine: values.domaine,
        duree: values.duree,
        specialisation: values.specialisation,
        etab_accueil: values.etab_accueil,
        lieu: values.lieu,
        date_obtention: values.date_obtention,
        diplome: values.diplome,
        etablissement: values.etablissement,
        en_secteur: values.en_secteur,
        // en_activite: values.en_activite,
        filiere: values.filiere,
        objectif: values.objectif,
        pdf: values.pdf,
        date_debut: values.date_debut,
      })

      .then((response) => {
        console.log(response.data.demandeurIdDemandeur);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <NavBar />
      <h1>Formulaire pour ajouter un demande stage</h1>
      <input
        className=""
        onChange={handleInput}
        type="hidden"
        value={values.demandeurid_demandeur}
        name="demandeurid_demandeur"
      />
      <div className="container ">
        <br />
        <h4 className="text-center">
          Formulaire pour ajouter un demande de stage
        </h4>
        <br />

        <form>
          <div className="d-flex flex-row row flexis">
            <div className="col-md-6">
              <div className="d-flex flex-column row">
                <div className=" col">
                  <label>Domaine:</label>
                  <input
                    className="form-control"
                    value={values.domaine}
                    onChange={handleInput}
                    placeholder="Votre domaine souhaité"
                    name="domaine"
                    required
                  />
                </div>

                <div className="col">
                  <label for="objectifStage">Durée de stage souhaitée:</label>
                  <select
                    class="form-select"
                    id="objectifStage"
                    aria-label="Floating label select example"
                    value={values.duree}
                    name="duree"
                    onChange={handleInput}
                  >
                    <option>Selectionner</option>

                    <option>1 mois</option>
                    <option>2 mois</option>
                    <option>3 mois</option>
                    <option>4 mois</option>
                    <option>5 mois</option>
                    <option>6 mois</option>
                  </select>
                </div>
                <div className="col">
                  <label for="objectifStage">Objectif envisager:</label>
                  <select
                    class="form-select"
                    id="objectifStage"
                    aria-label="Floating label select example"
                    value={values.objectif}
                    name="objectif"
                    onChange={handleInput}
                  >
                    <option>Selectionner</option>
                    <option>Obtention diplôme</option>
                    <option>Stage d'embauche</option>
                    <option>Test</option>
                  </select>
                </div>
                <div className="col">
                  <label>Votre filière</label>
                  <input
                    className="form-control"
                    placeholder="Filière"
                    value={values.filiere}
                    name="filiere"
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="col">
                  <label>Etablissement/Univeristé:</label>
                  <input
                    className="form-control"
                    placeholder="Etablissement d'origine"
                    value={values.etab_accueil}
                    onChange={handleInput}
                    name="etab_accueil"
                    required
                  />
                </div>
                <div className="col">
                  <label>Lieu de stage:</label>
                  <input
                    className="form-control"
                    placeholder="Lieu géographique préférentiel"
                    value={values.lieu}
                    onChange={handleInput}
                    name="lieu"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col">
                <label>Diplôme ou niveau d'etude:</label>
                <select
                  class="form-select"
                  id="objectifStage"
                  aria-label="Floating label select example"
                  value={values.diplome}
                  name="diplome"
                  onChange={handleInput}
                >
                  <option>Selectionner</option>
                  <option>Bacc</option>
                  <option>L1</option>
                  <option>L2</option>
                  <option>L3</option>
                  <option>M1</option>
                  <option>M2</option>
                  <option>Autres .....</option>
                </select>
              </div>
              <div className="col">
                <label>Date d'obtention de votre diplôme:</label>
                <input
                  value={values.date_obtention}
                  onChange={handleInput}
                  placeholder="Date d'obtention du diplôme"
                  className="form-control"
                  name="date_obtention"
                  required
                  type="date"
                />
              </div>

              <div className="col">
                <label>Sécteur d'activité souhaité:</label>
                <input
                  required
                  className="form-control"
                  placeholder="Sécteur"
                  value={values.en_secteur}
                  onChange={handleInput}
                  name="en_secteur"
                />
              </div>
              <div className="col">
                <label>Insérer votre CV ici</label>
                <input
                  required
                  value={values.pdf}
                  onChange={handleInput}
                  name="pdf"
                  type="file"
                  placeholder="CV"
                  className="form-control file"
                />
              </div>
              <div className="col">
                <label>Date du début de stage souhaité:</label>
                <input
                  type="date"
                  required
                  value={values.date_debut}
                  onChange={handleInput}
                  name="date_debut"
                  placeholder="pdf"
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Spécialisation:</label>
                <input
                  className="form-control"
                  onChange={handleInput}
                  type="text"
                  value={values.specialisation}
                  name="specialisation"
                  placeholder="Votre spécialisation"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-row-reverse justify-content-center"
            style={{ marginTop: 30 }}
          >
            <div>
              <button className="btn btn-primary" onClick={AddDemande}>
                Envoyer
              </button>
            </div>
          </div>
          <br />
          <br />
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Ajout_demande;
