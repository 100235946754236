import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/opport.css";
import url from "../../url";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
function Evenement() {
  const [data, setData] = useState([]);

  useEffect(() => {
    url
      .get("/opportunite")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const [setsearch] = useState("");
  // const handleChange = (e) => {
  //   setsearch(e.target.name.toLowerCase());
  // };

  // const handleChang = (event) => {
  //   console.log(event.target.value);
  //   setSelected(event.target.value);
  // };
  const [affichage, setAffichage] = useState(false);
  const Visible = () => {
    setAffichage(true);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <h5
        style={{
          width: "70%",
          marginLeft: "15%",
        }}
      >
        Notre site web offre une gamme diversifiée d'opportunités, allant des
        formations et bourses aux événements passionnants, pour vous aider à
        développer vos compétences, élargir vos horizons et saisir des occasions
        uniques.
      </h5>
      <p className="text-center filtre">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/">
              Tous
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/">
              Evènement
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/">
              Formation
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/">
              Bourse
            </a>
          </li>
        </ul>
      </p>
      <div className="opportunitecontainer" onMouseOver={Visible}>
        {data.map((item) => (
          <div key={item.id_opportunite} className="card_item">
            <div className="card_inner">
              <div className="  w-full">
                {item.choix === "Formation" ? (
                  <h5 className=" text-blue-500">{item.choix}</h5>
                ) : (
                  <h5 className=" text-red-500">{item.choix}</h5>
                )}
              </div>

              {/* <div class=" d-flex flex-col"> */}
                <div class="">
                  <h6 class="nom_auteur">{item.organisateur}</h6>
                </div>
              {/* </div> */}
              <p className=" text-center" style={{ fontSize: 15 }}>
                {item.contenu}
              </p>
              {affichage ? (
                <button
                  onClick={handleShow}
                  className="btn btn-primary text-white"
                >
                  Détails
                </button>
              ) : (
                <button disabled className="btn btn-primary text-white">
                  Détails
                </button>
              )}
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Détails de l'opportunité</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Déscription: </p>
                <p>Type:</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Fermer
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ))}
      </div>
    </>
  );
}

export default Evenement;
