import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/login.css";
import url from "../../url";

const Login = () => {
  const [email, setEmail] = useState("");
  const [motDePasse, setMotDePasse] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMotDePasseChange = (e) => {
    setMotDePasse(e.target.value);
  };

  const login = () => {
    const data = { email: email, mot_de_passe: motDePasse };
    url
      .post("/offreur/login", data)
      .then((response) => {
        if (response.data.error) alert(response.data.error);
        else {
          sessionStorage.setItem("accessToken", response.data.accessToken);
          navigate("/");
        }
      })
      .catch((error) => {
        alert("mot de passe incorect");
        navigate("/login");
        console.log(error);
      });
  };
  // Gestionnaire d'événements pour la soumission du formulaire
  // const handleSubmit = (e) => {};

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <img src="/image/log.png" className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="">
            <div className="card-body">
              <h3 className="text-center mb-4">Connectez-vous</h3>
              <form>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Adresse email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Mot de passe:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Mot de passe"
                    value={motDePasse}
                    onChange={handleMotDePasseChange}
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check"
                  />
                  <label htmlFor="check" className="form-check-label">
                    Se souvenir de moi
                  </label>
                </div>
                <div className="mb-3">
                  <Link
                    to="/reinitialisation"
                    className="oubli"
                    style={{ textDecoration: "none" }}
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={login}
                  className="btn btn-primary form-control"
                >
                  Se connecter
                </button>
                <div
                  className="mt-3"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p>Vous n'avez pas encore de compte ?</p>
                  <Link to="/inscription" style={{ textDecoration: "none" }}>
                    Inscription demandeur
                  </Link>
                  <Link to="/ajout_offreur" style={{ textDecoration: "none" }}>
                    Inscription offreur
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
