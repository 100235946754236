import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/dashboard.css";
import url from "../../url";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";
function ContenuOffre() {
  const column = [
    // {
    //   name: "id",
    //   selector: (row) => row.id_opportunite,
    //   sortable: true,
    // },
    {
      name: "Choix",
      selector: (row) => row.choix,
      sortable: true,
    },
    {
      name: "Contenu",
      selector: (row) => row.contenu,
      sortable: true,
    },
    {
      name: "Date debut",
      selector: (row) => row.date_debut,
      sortable: true,
    },
    {
      name: "Date fin",
      selector: (row) => row.date_fin,
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
      // sortable:true
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      // sortable:true
    },
    {
      name: "Durée",
      selector: (row) => row.duree,
      // sortable:true
    },
    {
      name: "Tarif",
      selector: (row) => row.tarif,
      // sortable:true
    },
    {
      name: "Organisateur",
      selector: (row) => row.organisateur,
      // sortable:true
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable:true
    },
    {
      name: "domaine",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            clickModal(
              // row.id_opportunite,
              row.choix,
              row.contenu,
              row.date_debut,
              row.date_fin,
              row.contact,
              row.adresse,
              row.duree,
              row.tarif,
              row.organisateur,
              row.status
            )
          }
        >
          <AiTwotoneEdit />
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button type="button" className="btn btn-danger">
          <MdDeleteOutline onClick={() => handleDelete(row.id_demande)} />
        </button>
      ),
    },
  ];

  const [values, setValues] = useState({
    choix: "",
    contenu: "",
    date_debut: "",
    date_fin: "",
    contact: "",
    adresse: "",
    duree: "",
    tarif: "",
    organisateur: "",
    status: "",
  });
  const [modal_modifie_offreur, setModal_modifie_offreur] = useState(false);
  const [v_choix, setV_choix] = useState("");
  const [v_contenu, setV_contenu] = useState("");
  const [v_date_debut, setV_date_debut] = useState("");
  const [v_date_fin, setV_date_fin] = useState("");
  const [v_duree, setV_duree] = useState("");
  const [v_tarif, setV_tarif] = useState("");
  const [v_organisateur, setV_organisateur] = useState("");
  const [v_status, setV_status] = useState("");
  const [v_contact, setV_contact] = useState("");
  const [v_adresse, setV_adresse] = useState("");
  const [v_id_opportunite, setV_id_opportunite] = useState("");

  const handleDelete = (index) => {
    const confirm = window.confirm("voulez vous supprimer ");
    if (confirm) {
      url
        .delete("/opportunite/" + index)
        .then((response) => alert("Suppression réussi!"))
        .catch((error) => alert(error));
    }
  };
  const clickModal = (
    // id_opportunite,
    choix,
    contenu,
    date_debut,
    date_fin,
    contact,
    adresse,
    duree,
    tarif,
    organisateur,
    status
  ) => {
    setModal_modifie_offreur(!modal_modifie_offreur);
    // setV_id_opportunite(id_opportunite);
    setV_choix(choix);
    setV_contenu(contenu);
    setV_date_debut(date_debut);
    setV_date_fin(date_fin);
    setV_contact(contact);
    setV_adresse(adresse);
    setV_duree(duree);
    setV_tarif(tarif);
    setV_organisateur(organisateur);
    setV_status(status);
    setEdit(true);
  };

  const handleAdd = () => {
    if (
      values.choix !== "" ||
      values.contenu !== "" ||
      values.date_debut !== "" ||
      values.date_fin !== "" ||
      values.contact !== "" ||
      values.adresse !== "" ||
      values.duree !== "" ||
      values.tarif !== "" ||
      values.organisateur !== "" ||
      values.status !== ""
    ) {
      url
        .post("/opportunite/", {
          // id_opportunite: values.id_opportunite,
          choix: values.choix,
          contenu: values.contenu,
          date_debut: values.date_debut,
          date_fin: values.date_fin,
          contact: values.contact,
          adresse: values.adresse,
          duree: values.duree,
          tarif: values.tarif,
          organisateur: values.organisateur,
          status: values.status,
        })
        .then((res) => {
          alert("Ajout avec succés");
        })
        .catch((err) => console.log(err));
    }
  };
  const handleEdit = (id) => {
    if (
      v_choix !== "" ||
      v_contenu !== "" ||
      v_date_debut !== "" ||
      v_date_fin !== "" ||
      v_contact !== "" ||
      v_adresse !== "" ||
      v_duree !== "" ||
      v_tarif !== "" ||
      v_organisateur !== "" ||
      v_status !== ""
    ) {
      url
        .put("/opportunite/" + id, {
          choix: v_choix,
          contenu: v_contenu,
          date_debut: v_date_debut,
          date_fin: v_date_fin,
          contact: v_contact,
          adresse: v_adresse,
          duree: v_duree,
          tarif: v_tarif,
          organisateur: v_organisateur,
          status: v_status,
        })
        .then((res) => {
          alert("Modification avec succés");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const fet_data = async () => {
      url
        .get("/opportunite/", {
          headers: {
            accessToken: sessionStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          setRecords(res.data);
          setrecordFilter(res.data);
        })
        .catch((err) => console.log(err));
    };
    fet_data();
  }, []);

  // const handleModifier = (idModif) => {
  //   axios
  //     .put("http://localhost:100/offreur/" + idModif, {
  //       headers: {
  //         accessToken: sessionStorage.getItem("accessToken"),
  //       },
  //     })
  //     .then((response) => {
  //       alert("succés");
  //     })
  //     .catch((error) => console.log(error));
  // };
  const [records, setRecords] = useState([]);
  const [recordfilter, setrecordFilter] = useState([]);
  const handlefilter = (e) => {
    const new_data = recordfilter.filter((row) =>
      row.entite.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRecords(new_data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [edit, setEdit] = useState(false);
  const handleCloseEdit = () => setEdit(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Gestion des demandes</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={column}
          data={records}
          // selectableRows
          fixedHeader
          pagination
        ></DataTable>
      </div>
      <Modal show={edit} onHide={handleCloseEdit}>
        <Modal.Header style={{ background: "#FFFF" }} closeButton>
          Modifier opportunite
        </Modal.Header>
        <Modal.Body>
          <label>Choix:</label>
          <select
            className="form-control"
            onChange={(e) => setV_choix(e.target.value)}
            value={v_choix}
            name=""
          >
            <option>Formation</option>
            <option>Evènement</option>
            <option>Bourse</option>
          </select>

          <label>Contenu:</label>
          <input
            className="form-control"
            onChange={(e) => setV_contenu(e.target.value)}
            value={v_contenu}
            name="contenu"
          />
          <label>Date de début:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setV_date_debut(e.target.value)}
            value={v_date_debut}
            name=""
          />
          <label>Date de fin:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setV_date_fin(e.target.value)}
            value={v_date_fin}
            name="date_fin"
          />
          <label>Contact:</label>
          <input
            type="number"
            className="form-control"
            onChange={(e) => setV_contact(e.target.value)}
            value={v_contact}
            name="contact"
          />
          <label>Adresse:</label>
          <input
            className="form-control"
            onChange={(e) => setV_adresse(e.target.value)}
            value={v_adresse}
            name="adresse"
          />
          <label>Durée:</label>
          <input
            className="form-control"
            onChange={(e) => setV_duree(e.target.value)}
            value={v_duree}
            name="duree"
          />
          <label>Tarif:</label>
          <input
            className="form-control"
            onChange={(e) => setV_tarif(e.target.value)}
            value={v_tarif}
            name="tarif"
          />
          <label>Organisateur:</label>
          <input
            className="form-control"
            onChange={(e) => setV_organisateur(e.target.value)}
            value={v_organisateur}
            name="organisateur"
          />
          <label>Status:</label>
          <select
            className="form-control"
            onChange={(e) => setV_status(e.target.value)}
            value={v_status}
          >
            <option>gratuit</option>
            <option>payant</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleEdit(v_id_opportunite)}
          >
            Modifier
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ background: "#" }} closeButton>
          Ajout demande
        </Modal.Header>
        <Modal.Body>
          <label>Choix:</label>
          <select
            className="form-control"
            onChange={handleInput}
            value={values.choix}
            name="choix"
            required
          >
            <option>Formation</option>
            <option>Evènement</option>
          </select>

          <label>Contenu:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.contenu}
            name="contenu"
            required
          />
          <label>Date de début:</label>
          <input
            type="date"
            className="form-control"
            onChange={handleInput}
            value={values.date_debut}
            name="date_debut"
            required
          />
          <label>Date de fin:</label>
          <input
            type="date"
            className="form-control"
            onChange={handleInput}
            value={values.date_fin}
            name="date_fin"
            required
          />
          <label>Contact:</label>
          <input
            type="number"
            className="form-control"
            onChange={handleInput}
            value={values.contact}
            name="contact"
            required
          />
          <label>Adresse:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.adresse}
            name="adresse"
            required
          />
          <label>Durée:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.duree}
            name="duree"
            required
          />
          <label>Tarif:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.tarif}
            name="tarif"
            required
            type="number"
          />
          <label>Organisateur:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.organisateur}
            name="organisateur"
            required
          />
          <label>Status:</label>
          <select
            className="form-control"
            onChange={handleInput}
            value={values.status}
            name="status"
            required
          >
            <option>gratuit</option>
            <option>payant</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleAdd()}
          >
            Ajouter
          </button>
        </Modal.Footer>
      </Modal>
      <button className="btn btn-success" onClick={(e) => setShow(true)}>
        +
      </button>
    </div>
  );
}

export default ContenuOffre;
