import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import NavBar from "../../layout/js/nav_bar";
import Footer from "../../layout/js/footer";
import axios from "axios";
import "../css/profil.css";

const Profil = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SECRET + "/demandeur/")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <NavBar />
      <h4 className="text-center"> Votre profil</h4>
      <div className="firstcontainer">
        {data.map((item) => (
          <div key={item.id} className="card_item">
            <div className="card_inner">
              <div class="d-flex justify-content-center">
                <img
                  src="image/person.png"
                  style={{ width: "70px", height: "70px" }}
                  alt=""
                  className="text-center"
                />
              </div>
              <h6 className="text-center">{item.nom}</h6>
              <p className="text-center">{item.sexe}</p>
              <p className="text-center">{item.mail}</p>
              <p className="text-center">{item.lieu_naissance}</p>
              <p className="text-center">+261{item.contact}</p>
              <button className="btn btn-dark" type="submit">
                Modifier
              </button>
            </div>
          </div>
        ))}
      </div>
      <Footer className="footer" />
    </>
  );
};
export default Profil;
