import React from "react";
import { Link } from "react-router-dom";
import "../css/recuperation.css";
function recuperation() {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <img src="/image/recup.png" className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center mb-4">Récupération mot de passe</h3>
              <form>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Mot de passe"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirmer votre mot de passe"
                  />
                </div>
                <div className="mb-3 d-grid">
                  <button className="btn btn-primary">Enregistrer</button>
                </div>
                <p className="paragraphe">
                  Vous avez déjà un compte? <Link to="/">Se connecter</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default recuperation;
