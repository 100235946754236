import React, { useState } from "react";
import "../css/ajout_offre.css";
import NavBar from "../../layout/js/nav_bar";
import Footer from "../../layout/js/footer";
import url from "../../url";

function Ajouter_Offre() {
  const [offreurid_offreur, setOffreurid] = useState("");
  const [domaine, setDomaine] = useState("");
  const [poste, setPoste] = useState("");
  const [lieu_stage, setLieu_stage] = useState("");
  const [duree_stage, setDuree_stage] = useState("");
  const [profil_demande, setProfil_demande] = useState("");
  const [condition, setCondition] = useState("");
  const [dossier_candidature, setDossier_candidature] = useState("");
  const [depot_candidature, setDepot_candidature] = useState("");

  const Reset = () => {
    setDomaine("");
    setPoste("");
    setLieu_stage("");
    setDuree_stage("");
    setCondition("");
    setDepot_candidature("");
    setDossier_candidature("");
    setProfil_demande("");
  };

  const Ajout_offre = () => {
    url
      .post("/offre", {
        offreurIdOffreur: offreurid_offreur,
        domaine: domaine,
        poste: poste,
        lieu_stage: lieu_stage,
        duree_stage: duree_stage,
        profil_demande: profil_demande,
        condition: condition,
        dossier_candidature: dossier_candidature,
        depot_candidature: depot_candidature,
      })
      .then((response) => {
        // Traiter la réponse ici
        alert("Votre publication a été créé avec succés");
        Reset();
      })
      .catch((error) => {
        console.error("Erreur lors de la requête Axios :", error);
        // Gérer l'erreur ici
      });
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <form>
          <div className="d-flex flex-column bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <label htmlFor="offreur_id" className="form-label">
                Offreur ID:
              </label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  className="form-control"
                  id="offreur_id"
                  name="offreur_id"
                  value={offreurid_offreur}
                  onChange={(e) => setOffreurid(e.target.value)}
                  placeholder="Offreur ID"
                />
              </div>
            </div>
            <div className="p-2 bd-highlight">
              <label htmlFor="domaine" className="form-label">
                Domaine:
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="domaine"
                  name="domaine"
                  value={domaine}
                  onChange={(e) => setDomaine(e.target.value)}
                  placeholder="Domaine"
                />
              </div>
            </div>
            {/* Add more fields in this section */}
            <div className="p-2 bd-highlight">
              <label htmlFor="poste" className="form-label">
                Poste de stage professionnel:
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="poste"
                  name="poste"
                  value={poste}
                  onChange={(e) => setPoste(e.target.value)}
                  placeholder="Poste de stage professionnel"
                />
              </div>
            </div>
            <label>Durée du stage</label>
            <div className="p-2 bd-highlight radio_input">
              <div className="input-group mb-3">
                <div class="d-flex flex-row2 ">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="duree_stage"
                      value="1 mois"
                      onClick={(e) => setDuree_stage(e.target.value)}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      1 mois
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="duree_stage"
                      value="2 mois"
                      onClick={(e) => setDuree_stage(e.target.value)}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      2 mois
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input "
                      type="radio"
                      name="duree_stage"
                      value="3 mois"
                      onClick={(e) => setDuree_stage(e.target.value)}
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      3 mois
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="duree_stage"
                      value="4 mois"
                      onClick={(e) => setDuree_stage(e.target.value)}
                    />
                    <label class="form-check-label" for="flexRadioDefault4">
                      4 mois
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="duree_stage"
                      id="flexRadioDefault5"
                      value="5 mois"
                      onClick={(e) => setDuree_stage(e.target.value)}
                    />
                    <label class="form-check-label" for="flexRadioDefault5">
                      5 mois
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="duree_stage"
                      value="6 mois"
                      onClick={(e) => setDuree_stage(e.target.value)}
                    />
                    <label class="form-check-label" for="flexRadioDefault6">
                      6 mois
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <fieldset
              className="p-2 bd-highlight"
              style={{ position: "relative", marginLeft: -18 }}
            >
              <p className="form-label">Dossier à fournir:</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="CV"
                  id="flexRadioCV"
                  value="CV"
                  onChange={(e) => setDossier_candidature(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioCV">
                  CV
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="LM"
                  id="flexRadioLM"
                  value="LM"
                  onChange={(e) => setDossier_candidature(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioLM">
                  Lettre de motivation
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diplome"
                  id="flexRadioDiplome"
                  value="diplome"
                  onChange={(e) => setDossier_candidature(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioDiplome">
                  Diplôme
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="Certificat"
                  id="flexRadioCertificat"
                  value="Certificat"
                  onChange={(e) => setDossier_candidature(e.target.value)}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexRadioCertificat"
                >
                  Certificat
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="Dossier"
                  id="flexRadioAutre"
                  value={dossier_candidature}
                  onChange={(e) => setDossier_candidature(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioAutre">
                  Autre
                </label>
              </div>
            </fieldset>
          </div>
          <div className="p-2 bd-highlight">
            <label htmlFor="condition" className="form-label">
              Condition de stage:
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                id="condition"
                name="condition"
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
                placeholder="Condition de stage"
              />
            </div>
          </div>
          <div className="p-2 bd-highlight">
            <label htmlFor="lieu_stage" className="form-label">
              Lieu de stage:
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                id="lieu_stage"
                name="lieu_stage"
                value={lieu_stage}
                onChange={(e) => setLieu_stage(e.target.value)}
                placeholder="Lieu de stage"
              />
            </div>
          </div>
          <div className="p-2 bd-highlight">
            <label htmlFor="depot_candidature" className="form-label">
              Date de clôture:
            </label>
            <div className="input-group mb-3">
              <input
                type="date"
                className="form-control"
                id="depot_candidature"
                name="depot_candidature"
                value={depot_candidature}
                onChange={(e) => setDepot_candidature(e.target.value)}
              />
            </div>
          </div>
        </form>
        <button className="btn btn-success" onClick={Ajout_offre}>
          Publier
        </button>
      </div>

      <Footer />
    </>
  );
}

export default Ajouter_Offre;
