import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../css/NavBar.css";
import { Link, useNavigate } from "react-router-dom";

const Nav_bar = () => {
  const [isConnected, setIsConnected] = React.useState(false);
  useEffect(() => {
    // Check if a specific key in session storage is not null
    const valueFromSessionStorage = sessionStorage.getItem("accessToken");

    if (valueFromSessionStorage !== null) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, []);
  const navigate = useNavigate();
  const LogOut = () => {
    const valueFromSessionStorage = sessionStorage.getItem("accessToken");
    if (valueFromSessionStorage) {
      sessionStorage.removeItem("accessToken");
      navigate("/login");
    } else {
      console.log("No access token found in session storage");
    }
  };

  return (
    <>
      <nav className="">
        <Navbar className="fixed-top bg-slate-50" collapseOnSelect expand="lg">
          <Container className="container-fluide">
            <Navbar.Brand>
              <img
                src="image/logo.png"
                className="ico"
                style={{ width: "79px", height: "50px" }}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link className="navlink pt-3">
                  <b>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      Accueil
                    </Link>
                  </b>
                </Nav.Link>
                <Nav.Link className="navlink pt-3">
                  <b>
                    <Link
                      to="/liste_offreur"
                      style={{ textDecoration: "none" }}
                    >
                      Offreurs
                    </Link>
                  </b>
                </Nav.Link>
                <Nav.Link className="navlink pt-3">
                  <Link to="/offre" style={{ textDecoration: "none" }}>
                    <b>Offres</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink pt-3">
                  <Link to="/demande" style={{ textDecoration: "none" }}>
                    <b>Demandes</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink pt-3">
                  <Link to="/documentation" style={{ textDecoration: "none" }}>
                    <b>Documentation</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink pt-3">
                  <Link to="/apropos" style={{ textDecoration: "none" }}>
                    <b>A propos</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="ml-4">
                  {isConnected ? (
                    <button onClick={LogOut} className="btn btn-info small">
                      <b>
                        <Link style={{ textDecoration: "none" }}>
                          <b className="text-white"> Se déconnecter</b>
                        </Link>
                      </b>
                    </button>
                  ) : (
                    <button
                      onClick={() => navigate("/login")}
                      className="btn btn-info small"
                    >
                      <b className=" text-white">Se connecter</b>
                    </button>
                  )}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </nav>
    </>
  );
};
export default Nav_bar;
