import React, { useState } from "react";
import "../css/opportunite.css";
import axios from "axios";
import Navbar from "../../layout/js/nav_bar";
import Footer from "../../layout/js/footer";
// import { Row, Col } from "react-bootstrap";

const Opportunite = () => {
  const [select, setSelect] = useState("");
  const [contenu, setContenu] = useState("");
  const [debut, setDebut] = useState("");
  const [fin, setFin] = useState("");
  const [contact, setContact] = useState("");
  const [adresse, setAdresse] = useState("");
  const [duree, setDuree] = useState("");
  const [tarif, setTarif] = useState("");
  const [organisateur, setOrganisateur] = useState("");
  const [choix, setChoix] = useState("");
  const [status, setStatus] = useState("");
  const [Error, setError] = useState("");

  const handleSubmit = (e) => {};
  const AjoutOpportunité = () => {
    axios
      .post(
        "http://localhost:100/opportunite",
        {
          headers: {
            accessToken: sessionStorage("accessToken"),
          },
        },
        {
          choix: select,
          date_debut: debut,
          date_fin: fin,
          contenu: contenu,
          contact: contact,
          duree: duree,
          tarif: tarif,
          organisateur: organisateur,
          adresse: adresse,
          status: status,
        }
      )

      .then((response) => {
        // Traiter la réponse ici
        alert("Ajout avec succés");
      })
      .catch((error) => {
        console.log(error);
        // Gérer l'erreur ici
      });
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="">
          <h3 className="text-center"> OPPORTUNITÉ </h3>
        </div>
        <form onSubmit={handleSubmit} className="">
          <div className="">
            <div className="form-group row">
              <div className="col-sm-6">
                <br></br>
              </div>
              <div className="col-sm-6">
                <br></br>
                <div className="select-container">
                  <label htmlFor="choix types">
                    <strong>choix types</strong>
                  </label>
                  <select
                    htmlFor="choix types"
                    required
                    id="continent"
                    value={select}
                    onChange={(e) => setSelect(e.target.value)}
                    name="select"
                    className="form-control bg-light"
                  >
                    <option value=""></option>
                    <option value="Évènement">Évènement</option>
                    <option value="Formation">Formation</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="inputboxe left">
            <input
              type="text"
              required
              className="form-control bg-light"
              name="contenu"
              value={contenu}
              onChange={(e) => setContenu(e.target.value)}
              placeholder="Contenu"
            />
          </div>
          <br></br>
          <div className="row">
            <div className="form-group row">
              <div className="col-sm-6">
                <br></br>
                <div className="select-container">
                  <div className="inputboxe left">
                    <label htmlFor="debut">
                      <strong>Date de début</strong>
                    </label>
                    <input
                      htmlFor="debut"
                      type="date"
                      required
                      className="form-control bg-light"
                      name="debut"
                      value={debut}
                      onChange={(e) => setDebut(e.target.value)}
                      placeholder="Date de début"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <br></br>
                <div className="select-container">
                  <div className="inputboxee left">
                    <label htmlFor="fin">
                      <strong>Date de fin</strong>
                    </label>
                    <input
                      htmlFor="fin"
                      type="date"
                      required
                      className="form-control bg-light"
                      name="fin"
                      value={fin}
                      onChange={(e) => setFin(e.target.value)}
                      placeholder="Date de fin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="inputboxe left">
            <input
              type="string"
              required
              className="form-control bg-light"
              name="contact"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Contact"
            />
          </div>
          <br></br>
          <div className="inputboxe left">
            <input
              required
              className="form-control bg-light"
              name="adresse"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              placeholder="Adresse"
            />
          </div>
          <br></br>
          <div className="row">
            <div className="form-group row">
              <div className="col-sm-6">
                <div className="inputboxe">
                  <input
                    required
                    className="form-control bg-light"
                    name="duree"
                    value={duree}
                    onChange={(e) => setDuree(e.target.value)}
                    placeholder="Durée"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="inputboxe">
                  <input
                    type="number"
                    required
                    className="form-control bg-light"
                    name="tarif"
                    value={tarif}
                    onChange={(e) => setTarif(e.target.value)}
                    placeholder="Tarif"
                  />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="form-group row">
              <div className="col-sm-6">
                <br></br>
                <div className="select-container">
                  <div className="inputboxe">
                    <input
                      required
                      className="form-control bg-light"
                      name="organisateur"
                      value={organisateur}
                      onChange={(e) => setOrganisateur(e.target.value)}
                      placeholder="Organisateur"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <br></br>
                <div className="select-container">
                  <label htmlFor="status">
                    <strong>Status</strong>
                  </label>
                  <select
                    htmlFor="status"
                    required
                    id="continent"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    name="status"
                    className="form-control bg-light"
                  >
                    <option value=""></option>
                    <option value="Gratuit">Gratuit</option>
                    <option value="Payant">Payant</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div>
            <div className="m-3">
              <button
                type="submit"
                onClick={AjoutOpportunité}
                className="btn btn-primary"
              >
                Ajouter
              </button>
              <button className="btn btn-outline">Annuler</button>
            </div>
          </div>
          {Error && <div className="">{Error}</div>}
        </form>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Opportunite;
