import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../css/NavBar.css";
import { Link } from "react-router-dom";
const NavBarOffreur = () => {
  return (
    <>
      <nav className="">
        <Navbar className="fixed-top bg-white" collapseOnSelect expand="lg">
          <Container className="container-fluide">
            <Navbar.Brand href="/">
              <img
                src="image/logo.png"
                className="ico"
                style={{ width: "79px", height: "50px" }}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link className="navlink">
                  <Link to="/">
                    <b>Accueil</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/liste_offreur">
                    <b>Offreurs</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/offre_offreur">
                    <b>Offres</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/demande">
                    <b>Demandes</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/postule-offreur">
                    <b>Postules</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/apropos">
                    <b>A propos</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/login">
                    <b>Se déconnecter</b>
                  </Link>
                </Nav.Link>
                <Nav.Link className="navlink">
                  <Link to="/profil_offreur">
                    <b>Profils</b>
                  </Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </nav>
    </>
  );
};
export default NavBarOffreur;
