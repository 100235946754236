import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useState, useRef, useEffect } from "react";
import "../css/try.css";

function Details() {
  const [titre, setTitre] = useState("");
  const [theme, setTheme] = useState("");
  const [discution, setDiscution] = useState("");
  const [errors, setErrors] = useState({
    titre: false,
    theme: false,
    discution: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorsObj = {
      titre: titre.trim() === "",
      theme: theme.trim() === "",
      discution: discution.trim() === "",
    };
    setErrors(errorsObj);
    if (titre && theme && discution) {
      console.log(
        "titre: ",
        titre,
        "\ntheme: ",
        theme,
        "\ndiscution: ",
        discution
      );
      // Here you can perform additional actions, e.g., send data to backend or reset the form
    }

    // Clear the input fields after successful data insertion
    setTitre("");
    setTheme("");
    setDiscution("");
  };
  return (
    <div className="Flex-container">
      <br />
      <div className="Flex-child left-section">
        <div id="row" className="row">
          <div className="shadow p-3 mb-4 bg-light rounded">
            <div className="header">
              <div className="icon">
                <img src="image/ellipse.png" alt="" />
                <h5 className="card-title"> Lorem upsem </h5>
              </div>
              <div className="title">{/* Title text goes here */}</div>
              <br></br>
            </div>
            <div classname="card-body">
              <div className="col-sm-6">
                <p classname="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
              <div className="footer">
                <div className="profile">
                  <div className="icon profile-icon mr-2">
                    <i
                      className="bi bi-person"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </div>
                  <div className="profile-name">John Doe</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="col" className="col-sm-8">
          <div className="shadow p-3 mb-4 bg-light rounded">
            <div classname="card-body">
              <div className="col-sm-6">
                <p classname="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
              <div className="footer">
                <div></div>
                <div className="profile">
                  <div className="icon profile-icon mr-2">
                    <i
                      className="bi bi-person"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </div>
                  <div className="profile-name">John Doe</div>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow p-3 mb-4 bg-light rounded">
            <div classname="card-body">
              <div className="col-sm-6">
                <p classname="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
              <div className="footer">
                <div></div>
                <div className="profile">
                  <div className="icon profile-icon mr-2">
                    <i
                      className="bi bi-person"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </div>
                  <div className="profile-name">John Doe</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="com">
          <div>
            <textarea
              required
              cols="400"
              className="form-control"
              id="bio"
              rows="3"
              placeholder="Commenter "
            ></textarea>
          </div>
          <div>
            <button className="btn btn-primary" type="submit">
              Commenter
            </button>
          </div>
        </div>
      </div>
      <div id="right" className="Flex-child right-section">
        <div classname="card">
          <div className="shadow p-5 mb-1 bg-light rounded">
            <h5 className="text-center">Créer Thème</h5>
            <div>
              <br></br>
              <form onSubmit={handleSubmit}>
                <div>
                  <div
                    className="input-group input-group-mg"
                    style={{ FontSize: "1.2 em" }}
                  >
                    <input
                      type="text"
                      name="titre"
                      className="form-control"
                      id="titre"
                      placeholder="Titre du Sujet"
                      required
                    />
                  </div>{" "}
                  <br></br>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="theme"
                      name="theme"
                      placeholder="theme"
                      required
                    />
                  </div>{" "}
                  <br></br>
                  <div>
                    <textarea
                      name="discution"
                      required
                      className="form-control"
                      id="discution"
                      rows="3"
                      placeholder="Votre discussion"
                    ></textarea>
                  </div>
                </div>
                <br></br>
                <div className="col-12">
                  <button className="btn btn-primary" type="submit">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Details;
