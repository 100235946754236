import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/dashboard.css";
import url from "../../url";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";
function Contenu() {
  const column = [
    // {
    //   name: "id",
    //   selector: (row) => row.id_offreur,
    //   sortable: true,
    // },
    {
      name: "Dénomination",
      selector: (row) => row.denomination,
      sortable: true,
    },
    {
      name: "Entité",
      selector: (row) => row.entite,
      sortable: true,
    },
    {
      name: "Sécteur",
      selector: (row) => row.secteur,
      sortable: true,
    },
    {
      name: "Activité",
      selector: (row) => row.secteur_activite,
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
      // sortable:true
    },
    {
      name: "Site",
      selector: (row) => row.site,
      // sortable:true
    },
    {
      name: "Localisation",
      selector: (row) => row.localisation,
      // sortable:true
    },
    {
      name: "Email",
      selector: (row) => row.email,
      // sortable:true
    },
    {
      name: "Mot de passe",
      selector: (row) => row.mo_de_passe,
      // sortable:true
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            clickModal(
              // row.id_offreur,
              row.site,
              row.contact,
              row.secteur_activite,
              row.secteur,
              row.entite,
              row.email,
              row.localisation,
              row.denomination,
              row.mot_de_passe
            )
          }
        >
          <AiTwotoneEdit />
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button type="button" className="btn btn-danger">
          <MdDeleteOutline onClick={() => handleDelete(row.id_offreur)} />
        </button>
      ),
    },
  ];

  const [values, setValues] = useState({
    // id_offreur: "",
    denomination: "",
    entite: "",
    secteur: "",
    secteur_activite: "",
    contact: "",
    site: "",
    localisation: "",
    email: "",
    mot_de_passe: "",
    mot_de_passe1: "",
  });
  const [modal_modifie_offreur, setModal_modifie_offreur] = useState(false);
  const [v_email, setV_mail] = useState("");
  const [v_localisation, setV_localisation] = useState("");
  const [v_site, setV_site] = useState("");
  const [v_contact, setV_contact] = useState("");
  const [v_secteur_activite, setV_secteur_activite] = useState("");
  const [v_secteur, setv_secteur] = useState("");
  const [v_entite, setV_entite] = useState("");
  const [v_denomination, setV_denomination] = useState("");
  const [v_id_offreur, setV_id_offreur] = useState("");
  const [v_mot_de_passe, setV_mot_de_passe] = useState("");
  const [v_mot_de_passe1, setV_mot_de_passe1] = useState("");
  const handleDelete = (index) => {
    const confirm = window.confirm("voulez vous supprimer ");
    if (confirm) {
      url
        .delete("/offreur/" + index)
        .then((response) => alert("Suppression réussi!"))
        .catch((error) => alert(error));
    }
  };
  const clickModal = (
    // id_offreur,
    site,
    contact,
    secteur_activite,
    secteur,
    entite,
    email,
    localisation,
    denomination,
    mot_de_passe
  ) => {
    setModal_modifie_offreur(!modal_modifie_offreur);
    // setV_id_offreur(id_offreur);
    setV_mail(email);
    setV_localisation(localisation);
    setV_site(site);
    setV_contact(contact);
    setV_secteur_activite(secteur_activite);
    setv_secteur(secteur);
    setV_entite(entite);
    setV_denomination(denomination);
    setV_mot_de_passe(mot_de_passe);
    setEdit(true);
  };

  const handleAdd = () => {
    if (
      values.denomination !== "" ||
      values.entite !== "" ||
      values.secteur !== "" ||
      values.secteur_activite !== "" ||
      values.contact !== "" ||
      values.site !== "" ||
      values.localisation !== "" ||
      values.email !== "" ||
      values.mot_de_passe !== "" ||
      values.mot_de_passe1 !== ""
    ) {
      url
        .post("/offreur/", {
          // id_offreur: values.id_offreur,
          denomination: values.denomination,
          entite: values.entite,
          secteur: values.secteur,
          secteur_activite: values.secteur_activite,
          contact: values.contact,
          site: values.site,
          localisation: values.localisation,
          email: values.email,
          mot_de_passe: values.mot_de_passe,
          mot_de_passe1: values.mot_de_passe1,
        })
        .then((res) => {
          alert("Ajout avec succés");
        })
        .catch((err) => console.log(err));
    }
  };
  const handleEdit = (id) => {
    if (
      v_denomination !== "" ||
      v_entite !== "" ||
      v_secteur !== "" ||
      v_secteur_activite !== "" ||
      v_contact !== "" ||
      v_site !== "" ||
      v_localisation !== "" ||
      v_email !== "" ||
      v_mot_de_passe !== "" ||
      v_mot_de_passe1 !== ""
    ) {
      url
        .put("/offreur/" + id, {
          denomination: v_denomination,
          entite: v_entite,
          secteur: v_secteur,
          secteur_activite: v_secteur_activite,
          contact: v_contact,
          site: v_site,
          localisation: v_localisation,
          email: v_email,
          mot_de_passe: v_mot_de_passe,
          mot_de_passe1: v_mot_de_passe1,
        })
        .then((res) => {
          alert("Modification avec succés");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const fet_data = async () => {
      url
        .get("/offreur/")
        .then((res) => {
          setRecords(res.data);
          setrecordFilter(res.data);
        })
        .catch((err) => console.log(err));
    };
    fet_data();
  }, []);

  // const handleModifier = (idModif) => {
  //   axios
  //     .put("http://localhost:100/offreur/" + idModif, {
  //       headers: {
  //         accessToken: sessionStorage.getItem("accessToken"),
  //       },
  //     })
  //     .then((response) => {
  //       alert("succés");
  //     })
  //     .catch((error) => console.log(error));
  // };
  const [records, setRecords] = useState([]);
  const [recordfilter, setrecordFilter] = useState([]);
  const handlefilter = (e) => {
    const new_data = recordfilter.filter((row) =>
      row.entite.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRecords(new_data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [edit, setEdit] = useState(false);
  const handleCloseEdit = () => setEdit(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Gestion des offreurs</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={column}
          data={records}
          // selectableRows
          fixedHeader
          pagination
        ></DataTable>
      </div>
      <Modal show={edit} onHide={handleCloseEdit}>
        <Modal.Header style={{ background: "#FFFF" }} closeButton>
          Modifier offreurs
        </Modal.Header>
        <Modal.Body>
          <div class="">
            {/* <input type="text" value={v_id_offreur} /> */}
            <label>Dénomination :</label> <br />
            <input
              type="text"
              onChange={(e) => setV_denomination(e.target.value)}
              name="denomination"
              value={v_denomination}
              className="form-control"
              required
              placeholder="dénomination"
            />
          </div>
          <div class="">
            <label>Sécteur d'activité:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_secteur_activite(e.target.value)}
              name="activite"
              value={v_secteur_activite}
              className="form-control"
              required
              placeholder="Sécteur d'activité"
            />
          </div>
          <div class="">
            <label>Entité:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_entite(e.target.value)}
              name="entite"
              value={v_entite}
              className="form-control"
              required
              placeholder="Entité"
            />
          </div>
          <div class="">
            <label>Sécteur:</label> <br />
            <input
              type="text"
              onChange={(e) => setv_secteur(e.target.value)}
              name="secteur"
              value={v_secteur}
              className="form-control"
              required
              placeholder="Sécteur"
            />
          </div>
          <div class="">
            <label>Contact rapide:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_contact(e.target.value)}
              name="contact"
              className="form-control"
              placeholder="Contact rapide"
              required
              value={v_contact}
            />
          </div>
          <div class="">
            <label>Email:</label> <br />
            <input
              type="email"
              onChange={(e) => setV_mail(e.target.value)}
              name="email"
              className="form-control"
              value={v_email}
              required
              placeholder="Email"
            />
          </div>
          <div class="">
            <label>Site:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_site(e.target.value)}
              name="site"
              className="form-control"
              value={v_site}
              required
              placeholder="Votre site web"
            />
          </div>
          <div class="">
            <label>Localisation:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_localisation(e.target.value)}
              name="localisation"
              className="form-control"
              value={v_localisation}
              required
              placeholder="Localisation"
            />
          </div>
          <div class="">
            <label>Mot de passe:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_mot_de_passe(e.target.value)}
              name="mot_de_passe"
              className="form-control"
              value={v_mot_de_passe}
              required
              placeholder="Localisation"
            />
          </div>{" "}
          <div class="">
            <label>Confirmer mot de passe:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_mot_de_passe1(e.target.value)}
              name="mot_de_passe1"
              className="form-control"
              value={v_mot_de_passe1}
              required
              placeholder="Localisation"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleEdit(v_id_offreur)}
          >
            Modifier
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ background: "#" }} closeButton>
          Ajout offreurs
        </Modal.Header>
        <Modal.Body>
          {/* <div class="">
            <label>Id offreur :</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="id_offreur"
              value={values.id_offreur}
              className="form-control"
              required
              placeholder="id_offreur"
            />
          </div> */}
          <div class="">
            <label>Dénomination :</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="denomination"
              value={values.denomination}
              className="form-control"
              required
              placeholder="dénomination"
            />
          </div>
          <div class="">
            <label>Sécteur d'activité:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="secteur_activite"
              value={values.secteur_activite}
              className="form-control"
              required
              placeholder="Sécteur d'activité"
            />
          </div>
          <div class="">
            <label>Entité:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="entite"
              value={values.entite}
              className="form-control"
              required
              placeholder="Entité"
            />
          </div>
          <div class="">
            <label>Sécteur:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="secteur"
              value={values.secteur}
              className="form-control"
              required
              placeholder="Sécteur"
            />
          </div>
          <div class="">
            <label>Contact rapide:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="contact"
              placeholder="Contact rapide"
              className="form-control"
              required
              value={values.contact}
            />
          </div>
          <div class="">
            <label>Email:</label> <br />
            <input
              type="email"
              onChange={handleInput}
              name="email"
              className="form-control"
              value={values.email}
              required
              placeholder="Email"
            />
          </div>
          <div class="">
            <label>Site:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="site"
              className="form-control"
              value={values.site}
              required
              placeholder="Votre site web"
            />
          </div>
          <div class="">
            <label>Localisation:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="localisation"
              className="form-control"
              value={values.localisation}
              required
              placeholder="Localisation"
            />
          </div>
          <div class="">
            <label>Mot de passe:</label> <br />
            <input
              type="password"
              onChange={handleInput}
              name="mot_de_passe"
              className="form-control"
              value={values.mot_de_passe}
              required
              placeholder="Localisation"
            />
          </div>
          <div class="">
            <label>Confirmer votre mot de passe:</label> <br />
            <input
              type="password"
              onChange={handleInput}
              name="mot_de_passe1"
              className="form-control"
              value={values.mot_de_passe1}
              required
              placeholder="mot de passe "
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleAdd()}
          >
            Ajouter
          </button>
        </Modal.Footer>
      </Modal>
      <button className="btn btn-success" onClick={(e) => setShow(true)}>
        +
      </button>
    </div>
  );
}

export default Contenu;
