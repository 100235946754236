import React from "react";
import Footer from "../../layout/js/footer";
import "../css/documentation.css";
import { FcReading } from "react-icons/fc";
import Nav_bar from "../../layout/js/nav_bar";

const Documentation = () => {
  const handleDownload = () => {
    const fileUrl =
      "http://math.univ-lyon1.fr/~omarguin/programmation/C++Polycop1.pdf"; // Replace with the actual URL
    window.open(fileUrl, "_blank");
  };

  return (
    <>
      <Nav_bar />
      <h6
        htmlFor=""
        style={{
          marginTop: "6%",
          width: "70%",
          marginLeft: "15%",
        }}
      >
        Découvrez une multitude de ressources et de documents variés, conçus
        pour enrichir vos connaissances et vous aider à approfondir votre
        compréhension dans de nombreux domaines
      </h6>
      <div className="container mt-4">
        <div className="table-responsive">
          <table className="table">
            <tbody className="tbody">
              <tr>
                <th className="cr">
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <FcReading
                      size={window.innerWidth < 768 ? 30 : 40}
                      style={{
                        border: "1px solid",
                        borderRadius: "50%",
                        borderColor: "#7CA4CA",
                        marginRight: "10px",
                      }}
                    />

                    <p>Cours de cuisine Malagasy</p>
                  </div>
                </th>
                <th>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                    <br />
                    <span className="bi bi-person">A.RABEKOTOSON</span>
                  </p>
                </th>
                <th>
                  <button onClick={handleDownload}>
                    <i className="bi bi-download"></i>
                  </button>
                </th>
              </tr>
              <tr>
                <th className="cr">
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <FcReading
                      size={window.innerWidth < 768 ? 30 : 40}
                      style={{
                        border: "1px solid",
                        borderRadius: "50%",
                        borderColor: "#7CA4CA",
                        marginRight: "10px",
                      }}
                    />

                    <p>Cours de cuisine Malagasy</p>
                  </div>
                </th>
                <th>
                  <ul>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                    <br />
                    <span className="bi bi-person ">A.RABEKOTOSON</span>
                  </ul>
                </th>
                <th>
                  <button
                    onClick={handleDownload}
                    className="bi bi-download down"
                  />
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Documentation;
