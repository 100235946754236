import React from "react";
import { Link } from "react-router-dom";
import "../css/reinitialisation.css";
function reinitialisation() {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <img
              alt=""
              src="image/oubli.png"
              style={{ width: "100%", height: "50%" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="card_reinit p-4">
            <h3 className="text-center">Mot de passe oublié</h3>
            <p className="para">
              Nous allons vous envoyer un lien pour réinitialiser votre mot de
              passe
            </p>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Votre email"
              />
            </div>
            <button type="button" className="btn btn-primary btn-block">
              Obtenir le lien de réinitialisation
            </button>
            <br />
            <Link to="/login" style={{ textDecoration: "none" }}>
              Retourner vers la page de connexion
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reinitialisation;
