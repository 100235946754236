import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import data_pubs from "./data_pub";

function Publicite() {
  // const contenu_pub={
  //   background-image:linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,128,0.5)), url({data_pub.image});
  //   height:100vh;
  //   width: 100%;
  //   background-position:center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  // }
  return (
    <>
      <div class="">
        <div class="container_pub">
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              EffectCube,
              Autoplay,
            ]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoplay={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {data_pubs.map((data_pub) => (
              <SwiperSlide key={data_pub.id} className="">
                <div class="sous_container_pub">
                  <div
                    class="contenu_pub m-0"
                    style={{
                      backgroundImage: `linear-gradient(to bottom, 
                  rgba(0,0,0,0.5), rgba(0,0,128,0.5)), url(${data_pub.photo})`,
                      height: "100vh",
                      width: "100%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <p className="text_pub">{data_pub.contenu}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div>
            <div class="footer_pub">
              <div>
                <div className="petit_text">
                  <div>
                    <p className="text-center">Découvrez tous les</p>
                    <p className="text-center">offres de stage à Madagascar</p>
                  </div>
                </div>
                <div>
                  <div class="input_footer_acceuil">
                    <div>
                      <img
                        className="img_valise"
                        src="image/valise.png"
                        alt=""
                      />
                      <input
                        type="text"
                        name="recherche_offre"
                        placeholder="Je recherche une poste"
                      />
                    </div>
                    <div>
                      <img src="image/lieu.png" alt="" />
                      <input
                        type="text"
                        name="recherche_offre_ville"
                        placeholder="Ville ou Region..."
                      />
                      <button >
                        <img src="image/loupe1.png" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Publicite;
