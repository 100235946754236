import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/inscription.css";
import url from "../../url";

function Inscription() {
  const [values, setValues] = useState({
    nom: "",
    prenom: "",
    date_naiss: "",
    lieu_naiss: "",
    contact: "",
    adresse: "",
    // image_file:'',
    specialisation: "",
    diplome: "",
    certificat: "",
    filiere_module: "",
    email: "",
    mdp: "",
    confirm_mdp: "",
  });
  const [errors, setErrors] = useState({});

  const handleInput = (e) => {
    // console.log(e.target)
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    // console.log(values)
  };

  function handleValidation(e) {
    e.preventDefault();
    // if(!errors){
    //     setErrors(Validation_form_inscription(values))
    // }else{
    //     alert("mandea")
    //     console.log('mandea')
    // }
  }

  const [page, setPage] = useState(1);

  function Retourn() {
    const retourPage = page - 1;
    setPage(retourPage);
  }

  function Suivant() {
    const suivantPage = page + 1;
    setPage(suivantPage);
  }

  return (
    <>
      <form onSubmit={handleValidation}>
        <div className="container">
          <div className="container">
            <div className="titre_inscription">
              <h3>
                Inscription pour les demandeurs de stage professionnel{" "}
                <span>: {page} /3</span>
              </h3>
            </div>

            {page === 1 ? (
              <div className="information_inscription">
                <div className="information_titre_identite">Identite</div>
                <div className="information_personnel row">
                  <div className="information_personnel_page1 col">
                    <div class="div_input">
                      <label>Nom :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="nom"
                        className="input form-control"
                        required
                        placeholder="Nom"
                      />
                      {errors.nom && <p class="p_errors">{errors.nom}</p>}
                    </div>
                    <div class="div_input">
                      <label>Prenom :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="prenom"
                        className="input"
                        required
                        placeholder="Prenom"
                      />
                      {errors.prenom && <p class="p_errors">{errors.prenom}</p>}
                    </div>
                    <div class="div_input">
                      <label>Date de naissance :</label> <br />
                      <input
                        type="date"
                        onChange={handleInput}
                        name="date_naiss"
                        className="input"
                        required
                        placeholder="Date de naissance"
                      />
                      {errors.date_naiss && (
                        <p class="p_errors">{errors.date_naiss}</p>
                      )}
                    </div>
                    <div class="div_input">
                      <label>Lieu de naissance :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="date_naiss"
                        className="input"
                        required
                        placeholder="Lieu/Region de naissance"
                      />
                      {errors.date_naiss && (
                        <p class="p_errors">{errors.date_naiss}</p>
                      )}
                    </div>
                  </div>
                  <div className="information_page2 col">
                    <div class="div_input">
                      <label>Contact :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="confirm_mdp"
                        className="input"
                        required
                        placeholder="Contact"
                      />
                      {errors.contact && (
                        <p class="p_errors">{errors.contact}</p>
                      )}
                    </div>
                    <div class="div_input">
                      <label>Adresse :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="adresse"
                        className="input"
                        required
                        placeholder="Adresse"
                      />
                      {errors.adresse && (
                        <p class="p_errors">{errors.adresse}</p>
                      )}
                    </div>
                    <div class="div_input">
                      <label>Email :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="email"
                        className="input"
                        required
                        placeholder="Email"
                      />
                      {errors.email && <p class="p_errors">{errors.email}</p>}
                    </div>
                    <div className="input_radio div">
                      <label className="label_sexe">Sexe</label>
                      <span className="input_radio_group">
                        <span className="input_radio_homme">
                          <input
                            name="sexe"
                            value="homme"
                            onChange={handleInput}
                            id="homme"
                            required
                            type="radio"
                          />
                          <label for="homme">Homme</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">Femme</label>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : page === 2 ? (
              <div className="information_inscription">
                <div className="information_titre_identite">
                  Parcours academique et formation
                </div>
                <div className="information_personnel row">
                  <div className="information_personnel_page1 col">
                    <div class="div_input">
                      <label>
                        Nom de l'etablissement de formation et Adresse :
                      </label>{" "}
                      <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="nom_etablissement"
                        className="input"
                        required
                        placeholder="Nom"
                      />
                    </div>
                    <div class="div_input">
                      <label>Filiere et module :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="filiere_module"
                        className="input"
                        required
                        placeholder="Prenom"
                      />
                    </div>
                    <div class="div_input">
                      <label>Specialisation :</label> <br />
                      <input
                        type="date"
                        onChange={handleInput}
                        name="specialisation"
                        className="input"
                        required
                        placeholder="Date de naissance"
                      />
                    </div>
                    <div class="div_input">
                      <label>Diplome ou certificat le plus eleve:</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="diplome_certificat"
                        className="input"
                        required
                        placeholder="Lieu/Region de naissance"
                      />
                    </div>
                    <div class="div_input">
                      <label>Date d'optention du diplome :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="date_obtension"
                        className="input"
                        required
                        placeholder="Contact"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="information_inscription">
                <div className="information_titre_identite">
                  Parcours et information sur le stage professionnel souhaite
                </div>
                <div className="information_personnel row">
                  <div className="information_personnel_page1 col">
                    <label className="label_sexe">
                      Duree du stage souhaitee
                    </label>
                    <div className="input_radio div">
                      <span className="input_radio_group">
                        <span className="input_radio_homme">
                          <input
                            name="sexe"
                            value="homme"
                            onChange={handleInput}
                            id="homme"
                            required
                            type="radio"
                          />
                          <label for="homme">1 mois</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">2 mois</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">3 mois</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">4 mois</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">5 mois</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">6 mois</label>
                        </span>
                      </span>
                    </div>
                    <label className="label_sexe">
                      Etes-vous en activite professionnelle (emploi) en ce
                      moment (a la date de votre inscription) :
                    </label>
                    <div className="input_radio div">
                      <span className="input_radio_group">
                        <span className="input_radio_homme">
                          <input
                            name="sexe"
                            value="homme"
                            onChange={handleInput}
                            id="homme"
                            required
                            type="radio"
                          />
                          <label for="homme">Oui</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">Non</label>
                        </span>
                      </span>
                    </div>
                    <div class="div_input">
                      <label>Si oui, dans quel secteur d'activite :</label>{" "}
                      <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="domaine specialise_souhaite"
                        className="input"
                        required
                        placeholder="Date de naissance"
                      />
                    </div>
                  </div>
                  <div className="information_page2 col">
                    <div class="div_input">
                      <label>Lieu geographique preferentiel :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="confirm_mdp"
                        className="input"
                        required
                        placeholder="Contact"
                      />
                      {errors.contact && (
                        <p class="p_errors">{errors.contact}</p>
                      )}
                    </div>
                    <div class="div_input">
                      <label>Adresse :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="adresse"
                        className="input"
                        required
                        placeholder="Adresse"
                      />
                      {errors.adresse && (
                        <p class="p_errors">{errors.adresse}</p>
                      )}
                    </div>
                    <div class="div_input">
                      <label>Email :</label> <br />
                      <input
                        type="text"
                        onChange={handleInput}
                        name="email"
                        className="input"
                        required
                        placeholder="Email"
                      />
                      {errors.email && <p class="p_errors">{errors.email}</p>}
                    </div>
                    <div className="input_radio div">
                      <label className="label_sexe">Sexe</label>
                      <span className="input_radio_group">
                        <span className="input_radio_homme">
                          <input
                            name="sexe"
                            value="homme"
                            onChange={handleInput}
                            id="homme"
                            required
                            type="radio"
                          />
                          <label for="homme">Homme</label>
                        </span>
                        <span className="input_radio_femme">
                          <input
                            name="sexe"
                            value="femme"
                            onChange={handleInput}
                            id="femme"
                            required
                            type="radio"
                          />
                          <label for="femme">Femme</label>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="btn_pagination_inscription">
              <div>
                {page > 1 && (
                  <button
                    type="button"
                    class="btn_retour_inscription"
                    onClick={Retourn}
                  >
                    Retour
                  </button>
                )}
                {page < 3 && (
                  <button
                    type="button"
                    class="btn_suivant_inscription"
                    onClick={Suivant}
                  >
                    Suivant
                  </button>
                )}
                {page === 3 && (
                  <button type="submit" class="btn_suivant_inscription">
                    S'inscrire
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Inscription;
