import React from "react";
import { Link } from "react-router-dom";
import "../css/modification.css";
function modification() {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <img src="/image/recup.png" className="img-fluid" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center mb-4">Modification de mot de passe</h3>
              <form>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Adresse email"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Mot de passe Actuel"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Nouveau mot de passe"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirmer votre mot de passe"
                  />
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary modif btn-block">
                    Modifier
                  </button>
                </div>
                <p className="ppp">
                  Vous avez déjà un compte?{" "}
                  <Link to="/ajout">Se connecter</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default modification;
