import React, { useState } from "react";
import "../css/post.css";
import url from "../../url";

const FileUpload = () => {
  const [demandeur, setDemandeur] = useState("");
  const [offre, setOffre] = useState("");
  const [cv, setCv] = useState(null);
  const [lm, setLm] = useState(null);
  const [certification, setCertification] = useState(null);
  const Ajout = () => {
    const formdata = new FormData();
    formdata.append("demandeurIdDemandeur", demandeur);
    formdata.append("offreIdOffre", offre);
    formdata.append("lm", lm);
    formdata.append("cv", cv);
    formdata.append("certification", certification);

    url
      .post("/postule", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // lm: lm,
        // cv: cv,
        // certification: certification,
      })
      .then((res) => alert(res))
      .catch((error) => alert(error));
  };

  return (
    <>
      <div className="firstcontainer">
        <div className="card_item">
          <div className="card_inner">
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                name="demandeurIdDemandeur"
                value={demandeur}
                onChange={(e) => setDemandeur(e.target.value)}
              />
              <br />
              <input
                type="text"
                className="form-control"
                placeholder="id_offre"
                name="offreIdOffre"
                value={offre}
                onChange={(e) => setOffre(e.target.value)}
              />
              <br />
              <input
                className="form-control"
                name="cv"
                onChange={(e) => {
                  setCv(e.target.files[0]);
                }}
                type="file"
                placeholder="Insérer CV"
              />
              <br />
              <input
                className="form-control"
                name="lm"
                onChange={(e) => {
                  setLm(e.target.files[0]);
                }}
                type="file"
                placeholder="Insérer LM"
              />
              <br />
              <input
                className="form-control"
                name="certification"
                onChange={(e) => {
                  setCertification(e.target.files[0]);
                }}
                placeholder="Insérer certification"
                type="file"
              />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => Ajout()}
              >
                Postuler
              </button>
            </div>
            <br></br>
          </div>
        </div>
      </div>
      <div className="carde form"></div>
    </>
  );
};

export default FileUpload;
