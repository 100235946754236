import React, { useState, useEffect } from "react";
import "../css/offreur.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../css/Card.css";
import NavBar from "../../layout/js/nav_bar";
import Footer from "../../layout/js/footer";
import { BsTelephoneFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import url from "../../url";

const Offreur = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await url.get(`/offreur/`, {
          headers: {
            accessToken: sessionStorage.getItem("accessToken"),
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <NavBar />
      <h6
        className="text-center"
        style={{
          marginTop: "6%",

          width: "70%",
          marginLeft: "15%",
        }}
      >
        Découvrez nos partenaires offreur de stage qui sont des entreprises
        renommées et des organisations innovantes, prêts à accueillir des
        stagiaires talentueux pour une expérience professionnelle enrichissante.
      </h6>
      <div className="firstcontainer">
        {data.map((item) => (
          <div key={item.id_offreur} className="card_item">
            <div
              className="card_inner"
              style={{ width: "300px", height: "200px" }}
            >
              {/* <FcBriefcase
                size={60}
                style={{
                  marginLeft: 5,
                  border: "1px solid turquoise",
                  background: "turquoise",
                  borderRadius: "50%",
                }}
              /> */}
              <strong>
                {/* <p className="text-center" style={{ fontSize: 20 }}>
                  {item.entite}
                </p> */}
                {item.denomination}
              </strong>
              <p>{item.entite} | {item.secteur}</p>
              <p className="">{item.activite}</p>
              <p
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2%",
                }}
              >
                <BsTelephoneFill style={{ marginTop: "3%" }} />
                +261{item.contact}
              </p>
              <p
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2%",
                }}
              >
                <AiTwotoneMail style={{ marginTop: "2%" }} /> {item.email}
              </p>
              <p
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2%",
                }}
              >
                <TbWorld style={{ marginTop: "2%" }} />
                {item.site}
              </p>

              <p
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2%",
                }}
              >
                {item.localisation}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Footer className="footer" />
    </>
  );
};

export default Offreur;
