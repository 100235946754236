import React, { useEffect, useState } from "react";
import url from "../../url";
import { Button, Modal } from "react-bootstrap";
import Modal_opportunite from "./modal_opportunite";
const Detail_opportunite = () => {
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [choix, setChoix] = useState("");
  const [contenu, setcontenu] = useState("");
  const [date_debut, setDate_debut] = useState("");
  const [date_fin, setDate_fin] = useState("");
  const [duree, setDuree] = useState("");
  const [tarif, setTarif] = useState("");
  const [organisateur, setorganisateur] = useState("");
  const [status, setStatus] = useState("");
  const [contact, setContact] = useState("");
  const [adresse, setAdresse] = useState("");
  const [values, setValues] = useState({
    choix: "",
    contenu: "",
    date_debut: "",
    date_fin: "",
    contact: "",
    adresse: "",
    duree: "",
    tarif: "",
    organisateur: "",
    status: "",
  });
  useEffect(() => {
    url
      .get("/opportunite/", {
        headers: {
          accessToken: sessionStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleDelete = (iden) => {
    const confirm = window.confirm("Voulez vous supprimer");
    if (confirm) {
      url
        .delete("/opportunite/" + iden)
        .then((response) => {
          alert("Suppression avec succés");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const HandleModif = (ident) => {
    url
      .put("/opportunite/" + ident, {
        choix: choix,
        contenu: contenu,
        date_debut: date_debut,
        date_fin: date_fin,
        contact: contact,
        adresse: adresse,
        duree: duree,
        tarif: tarif,
        organisateur: organisateur,
        status: status,
      })

      .then((response) => {
        alert("modification avec succés");
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
        // Gérer l'erreur ici
      });
  };
  const handleAdd = () => {
    if (
      values.choix !== "" ||
      values.contenu !== "" ||
      values.date_debut !== "" ||
      values.date_fin !== "" ||
      values.contact !== "" ||
      values.adresse !== "" ||
      values.duree !== "" ||
      values.tarif !== "" ||
      values.organisateur !== "" ||
      values.status !== ""
    ) {
      url
        .post("/opportunite/", {
          headers: {
            accessToken: sessionStorage.getItem("accesstoken"),
          },
          choix: values.choix,
          contenu: values.contenu,
          date_debut: values.date_debut,
          date_fin: values.date_fin,
          contact: values.contact,
          adresse: values.adresse,
          duree: values.duree,
          tarif: values.tarif,
          organisateur: values.organisateur,
          status: values.status,
        })

        .then((response) => {
          if (response) alert("Inscription avec succés");
        })
        .catch((error) => {
          console.log(error);
          // Gérer l'erreur ici
        });
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleEdit = (item) => {
    setChoix(item.choix);
    setcontenu(item.contenu);
    setDate_debut(item.date_debut);
    setDate_fin(item.date_fin);
    setContact(item.contact);
    setAdresse(item.adresse);
    setDuree(item.duree);
    setTarif(item.tarif);
    setorganisateur(item.organisateur);
    setStatus(item.status);
    setShowAdd(true);
  };

  return (
    <div>
      <button className="btn btn-success" onClick={() => handleShow()}>
        Ajouter
      </button>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Choix</th>
            <th scope="col">Contenu</th>
            <th scope="col">Date_debut</th>
            <th scope="col">Date_fin</th>
            <th scope="col">Contact</th>
            <th scope="col">Adresse</th>
            <th scope="col">Duree</th>
            <th scope="col">Tarif</th>
            <th scope="col">Organisateur</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.choix}</td>
              <td>{item.contenu}</td>
              <td>{item.date_debut}</td>
              <td>{item.date_fin}</td>
              <td>{item.contact}</td>
              <td>{item.adresse}</td>
              <td>{item.duree}</td>
              <td>{item.tarif}</td>
              <td>{item.organisateur}</td>
              <td>{item.status}</td>
              <Modal key={index} show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h5 className="text-center">Modification opportunité</h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label>Choix:</label>
                  <select
                    className="form-control"
                    onChange={(e) => setChoix(e.target.value)}
                    value={choix}
                    name=""
                  >
                    <option>Formation</option>
                    <option>Evènement</option>
                    <option>Bourse</option>
                  </select>

                  <label>Contenu:</label>
                  <input
                    className="form-control"
                    onChange={(e) => setcontenu(e.target.value)}
                    value={contenu}
                    name="contenu"
                  />
                  <label>Date de début:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setDate_debut(e.target.value)}
                    value={date_debut}
                    name=""
                  />
                  <label>Date de fin:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setDate_fin(e.target.value)}
                    value={date_fin}
                    name="date_fin"
                  />
                  <label>Contact:</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                    name="contact"
                  />
                  <label>Adresse:</label>
                  <input
                    className="form-control"
                    onChange={(e) => setAdresse(e.target.value)}
                    value={adresse}
                    name="adresse"
                  />
                  <label>Durée:</label>
                  <input
                    className="form-control"
                    onChange={(e) => setDuree(e.target.value)}
                    value={duree}
                    name="duree"
                  />
                  <label>Tarif:</label>
                  <input
                    className="form-control"
                    onChange={(e) => setTarif(e.target.value)}
                    value={tarif}
                    name="tarif"
                  />
                  <label>Organisateur:</label>
                  <input
                    className="form-control"
                    onChange={(e) => setorganisateur(e.target.value)}
                    value={organisateur}
                    name="organisateur"
                  />
                  <label>Status:</label>
                  <select
                    className="form-control"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    <option>gratuit</option>
                    <option>payant</option>
                  </select>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAdd}>
                    Close
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => HandleModif(item.id_opportunite)}
                  >
                    Modifier
                  </Button>
                </Modal.Footer>
              </Modal>
              <td>
                <div>
                  <button onClick={() => handleEdit(item)}>edit</button>
                </div>
                <div>
                  <button onClick={(e) => handleDelete(item.id_opportunite)}>
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-center">Ajout opportunité</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Choix:</label>
          <select
            className="form-control"
            onChange={handleInput}
            value={values.choix}
            name="choix"
            required
          >
            <option>Formation</option>
            <option>Evenement</option>
          </select>

          <label>Contenu:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.contenu}
            name="contenu"
            required
          />
          <label>Date de début:</label>
          <input
            type="date"
            className="form-control"
            onChange={handleInput}
            value={values.date_debut}
            name="date_debut"
            required
          />
          <label>Date de fin:</label>
          <input
            type="date"
            className="form-control"
            onChange={handleInput}
            value={values.date_fin}
            name="date_fin"
            required
          />
          <label>Contact:</label>
          <input
            type="number"
            className="form-control"
            onChange={handleInput}
            value={values.contact}
            name="contact"
            required
          />
          <label>Adresse:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.adresse}
            name="adresse"
            required
          />
          <label>Durée:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.duree}
            name="duree"
            required
          />
          <label>Tarif:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.tarif}
            name="tarif"
            required
            type="number"
          />
          <label>Organisateur:</label>
          <input
            className="form-control"
            onChange={handleInput}
            value={values.organisateur}
            name="organisateur"
            required
          />
          <label>Status:</label>
          <select
            className="form-control"
            onChange={handleInput}
            value={values.status}
            name="status"
            required
          >
            <option>gratuit</option>
            <option>payant</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={() => handleAdd()}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Detail_opportunite;
