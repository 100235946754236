import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import NavBarOffreur from "../../layout/js/navbar_offreur";
import Footer from "../../layout/js/footer";
import axios from "axios";
import "../css/profil.css";

const Profil = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SECRET + "/offreur/id", {
        headers: {
          Authorization: sessionStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <NavBarOffreur />
      <h4 className="text-center"> Votre profil</h4>
      <div className="firstcontainer">
        <div key={data.id} className="card_item">
          <div className="card_inner">
            <div class="d-flex justify-content-center">
              <img
                src="image/person.png"
                style={{ width: "70px", height: "70px" }}
                alt=""
                className="text-center"
              />
            </div>
            <h6 className="text-center">{data.denomination}</h6>
            <p className="text-center">{data.secteur}</p>
            <p className="text-center">{data.entite}</p>
            <p className="text-center">{data.activite}</p>
            <p className="text-center">+261{data.contact}</p>
            <p className="text-center">{data.localisation}</p>
            <p className="text-center">{data.site}</p>
            <p className="text-center">{data.email}</p>
            <button className="btn btn-dark" type="submit">
              Modifier
            </button>
          </div>
        </div>
      </div>
      <Footer className="footer" />
    </>
  );
};
export default Profil;
