import React from "react";
import Navbar from "../../layout/js/nav_bar";
import ContenuCopy from "./Contenu_copy";
// import Contenu_copy2 from './Contenu_copy2';

function Admin() {
  return (
    <div>
      <Navbar />
      <ContenuCopy />
    </div>
  );
}

export default Admin;
