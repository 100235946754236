import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import data_pubs from "./data_pub";
import "../css/accueil.css";

function Partenariat() {
  return (
    <>
      <h5>
        Nous sommes fiers de collaborer avec nos partenaires de confiance, des
        entreprises et des organisations qui partagent notre vision et notre
        engagement à offrir des solutions de qualité.
      </h5>
      <div class="container">
        <div class="container container_partenaire row">
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              EffectCube,
              Autoplay,
            ]}
            spaceBetween={50}
            slidesPerView={4}
            navigation
            autoplay={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {data_pubs.map((data_pub) => (
              <SwiperSlide key={data_pub.id}>
                <div class="sous_container_partenaire card">
                  <div class="photo_partenaire">
                    <img src="image/exemple_logo.png" />
                    <div />
                    <div class="contenu_partenaire">
                      <div className="titres">
                        <p className="">{data_pub.contenu}</p>
                      </div>
                      <div className="domaine">
                        <p>{data_pub.detail}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Partenariat;
