import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/dashboard.css";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";
import url from "../../url";
import NavBarAdmin from "./nav_bar_admin";

function ContenuOffre() {
  // Use apiEndpoint to make API requests in your application.

  const column = [
    // {
    //   name: "id",
    //   selector: (row) => row.id_demande,
    //   sortable: true,
    // },
    // {
    //   name: "Id Demandeur",
    //   selector: (row) => row.demandeurIdDemandeur,
    //   sortable: true,
    // },
    {
      name: "etablissement",
      selector: (row) => row.etablissement,
      sortable: true,
    },
    {
      name: "Objectif",
      selector: (row) => row.objectif,
      sortable: true,
    },
    {
      name: "Durée de stage",
      selector: (row) => row.duree,
      sortable: true,
    },
    {
      name: "Filière",
      selector: (row) => row.filiere,
      // sortable:true
    },
    {
      name: "Spécialisation",
      selector: (row) => row.specialisation,
      // sortable:true
    },
    {
      name: "Diplôme",
      selector: (row) => row.diplome,
      // sortable:true
    },
    {
      name: "Date d'obtention",
      selector: (row) => row.date_obtention,
      // sortable:true
    },
    {
      name: "Activite",
      selector: (row) => row.en_activite,
      // sortable:true
    },
    {
      name: "Sécteur d'activite",
      selector: (row) => row.en_secteur,
      // sortable:true
    },
    {
      name: "Domaine",
      selector: (row) => row.domaine,
      // sortable:true
    },
    {
      name: "Lieu",
      selector: (row) => row.lieu,
      // sortable:true
    },
    {
      name: "établissement d'accueil",
      selector: (row) => row.etab_accueil,
      // sortable:true
    },
    {
      name: "CV",
      selector: (row) => row.pdf,
      // sortable:true
    },
    {
      name: "domaine",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            clickModal(
              // row.id_demande,
              // row.demandeurIdDemandeur,
              row.etablissement,
              row.objectif,
              row.duree,
              row.filiere,
              row.specialisation,
              row.diplome,
              row.date_obtention,
              row.en_activite,
              row.en_secteur,
              row.domaine,
              row.lieu,
              row.etab_accueil
            )
          }
        >
          <AiTwotoneEdit />
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button type="button" className="btn btn-danger">
          <MdDeleteOutline onClick={() => handleDelete(row.id_demande)} />
        </button>
      ),
    },
  ];

  const [values, setValues] = useState({
    // id_demande: "",
    // demandeurIdDemandeur: "",
    etablissement: "",
    objectif: "",
    duree: "",
    filiere: "",
    specialisation: "",
    diplome: "",
    date_obtention: "",
    en_activite: "",
    en_secteur: "",
    domaine: "",
    lieu: "",
    etab_accueil: "",
  });
  const [modal_modifie_offreur, setModal_modifie_offreur] = useState(false);
  // const [v_demandeurIdDemandeur, setV_demandeurIdDemandeur] = useState("");
  const [v_etablissement, setV_etablissement] = useState("");
  const [v_objecif, setV_objectif] = useState("");
  const [v_duree, setV_duree] = useState("");
  const [v_date_obtention, setV_date_obtention] = useState("");
  const [v_diplome, setv_diplome] = useState("");
  const [v_en_secteur, setV_en_secteur] = useState("");
  const [v_specialisation, setV_specialisation] = useState("");
  const [v_id_demande, setV_id_demande] = useState("");
  const [v_en_activite, setV_en_activite] = useState("");
  const [v_domaine, setV_domaine] = useState("");
  const [v_etab_accueil, setV_etab_accueil] = useState("");
  const [v_lieu, setV_lieu] = useState("");
  const [v_filiere, setV_filiere] = useState("");

  const handleDelete = (index) => {
    const confirm = window.confirm("voulez vous supprimer ");
    if (confirm) {
      url
        .delete("/demande/" + index)
        .then((response) => alert("Suppression réussi!"))
        .catch((error) => alert(error));
    }
  };
  const clickModal = (
    // id_demande,
    // demandeurIdDemandeur,
    etablissement,
    objectif,
    duree,
    filiere,
    specialisation,
    diplome,
    date_obtention,
    en_activite,
    en_secteur,
    domaine,
    lieu,
    etab_accueil
  ) => {
    setModal_modifie_offreur(!modal_modifie_offreur);
    // setV_id_demande(id_demande);
    setV_objectif(objectif);
    setV_domaine(domaine);
    setV_etablissement(etablissement);
    setV_duree(duree);
    setV_filiere(filiere);
    setV_specialisation(specialisation);
    setv_diplome(diplome);
    setV_date_obtention(date_obtention);
    setV_en_activite(en_activite);
    setV_en_secteur(en_secteur);
    setV_lieu(lieu);
    // setV_demandeurIdDemandeur(demandeurIdDemandeur);
    setV_etab_accueil(etab_accueil);
    setEdit(true);
  };

  const handleAdd = () => {
    if (
      // values.id_demande !== "" ||
      // values.demandeurIdDemandeur !== "" ||
      values.etablissement !== "" ||
      values.objectif !== "" ||
      values.duree !== "" ||
      values.filiere !== "" ||
      values.specialisation !== "" ||
      values.diplome !== "" ||
      values.date_obtention !== "" ||
      values.en_activite !== "" ||
      values.en_secteur !== "" ||
      values.domaine !== "" ||
      values.lieu !== "" ||
      values.etab_accueil !== ""
    ) {
      url
        .post("/demande/", {
          // id: values.id_demande,
          // id_demandeur: values.demandeurIdDemandeur,

          etablissement: values.etablissement,
          objectif: values.objectif,
          duree: values.duree,
          filiere: values.filiere,
          specialisation: values.specialisation,
          diplome: values.diplome,
          date_obtention: values.date_obtention,
          en_activite: values.en_activite,
          en_secteur: values.en_secteur,
          domaine: values.domaine,
          lieu: values.lieu,
          etab_accueil: values.etab_accueil,
        })
        .then((res) => {
          alert("Ajout avec succés");
        })
        .catch((err) => console.log(err));
    }
  };
  const handleEdit = (id) => {
    if (
      // values.id_demande !== "" ||
      // values.demandeurIdDemandeur !== "" ||
      values.etablissement !== "" ||
      values.objectif !== "" ||
      values.duree !== "" ||
      values.filiere !== "" ||
      values.specialisation !== "" ||
      values.diplome !== "" ||
      values.date_obtention !== "" ||
      values.en_activite !== "" ||
      values.en_secteur !== "" ||
      values.domaine !== "" ||
      values.lieu !== "" ||
      values.etab_accueil !== ""
    ) {
      url
        .put("/demande/" + id, {
          // demandeurIdDemandeur: v_demandeurIdDemandeur,
          etablissement: v_etablissement,
          objectif: v_objecif,
          duree: v_duree,
          filiere: v_filiere,
          specialisation: v_specialisation,
          diplome: v_diplome,
          date_obtention: v_date_obtention,
          en_activite: v_en_activite,
          en_secteur: v_en_secteur,
          domaine: v_domaine,
          lieu: v_lieu,
          etab_accueil: v_etab_accueil,
        })
        .then((res) => {
          alert("Modification avec succés");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const fet_data = async () => {
      url
        .get("/demande", {
          headers: {
            accessToken: sessionStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          setRecords(res.data);
          setrecordFilter(res.data);
        })
        .catch((err) => console.log(err));
    };
    fet_data();
  }, []);

  // const handleModifier = (idModif) => {
  //   axios
  //     .put("http://localhost:100/offreur/" + idModif, {
  //       headers: {
  //         accessToken: sessionStorage.getItem("accessToken"),
  //       },
  //     })
  //     .then((response) => {
  //       alert("succés");
  //     })
  //     .catch((error) => console.log(error));
  // };
  const [records, setRecords] = useState([]);
  const [recordfilter, setrecordFilter] = useState([]);
  const handlefilter = (e) => {
    const new_data = recordfilter.filter((row) =>
      row.entite.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRecords(new_data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [edit, setEdit] = useState(false);
  const handleCloseEdit = () => setEdit(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div>
      <NavBarAdmin />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Gestion des demandes</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={column}
          data={records}
          // selectableRows
          fixedHeader
          pagination
        ></DataTable>
      </div>
      <Modal show={edit} onHide={handleCloseEdit}>
        <Modal.Header style={{ background: "#FFFF" }} closeButton>
          Modifier demande
        </Modal.Header>
        <Modal.Body>
          {/* <div class="">
            <input type="text" value={v_id_demande} />
            <label>id demandeur :</label> <br />
            <input
              type="text"
              onChange={(e) => setV_demandeurIdDemandeur(e.target.value)}
              name="v_demandeurIdDemandeur"
              value={v_demandeurIdDemandeur}
              className="form-control"
              required
              placeholder="id demandeur"
            />
          </div> */}
          <div class="">
            <label>Etablissement:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_etablissement(e.target.value)}
              name="v_etablissement"
              value={v_etablissement}
              className="form-control"
              required
              placeholder="Sécteur d'activité"
            />
          </div>
          <div class="">
            <label>objecif:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_objectif(e.target.value)}
              name="v_objecif"
              value={v_objecif}
              className="form-control"
              required
              placeholder="Poste"
            />
          </div>
          <div class="">
            <label>Duree stage:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_duree(e.target.value)}
              name="v_duree"
              value={v_duree}
              className="form-control"
              required
              placeholder="durée"
            />
          </div>
          <div class="">
            <label>Diplôme:</label> <br />
            <input
              type="text"
              onChange={(e) => setv_diplome(e.target.value)}
              name="v_diplome"
              className="form-control"
              required
              value={v_diplome}
            />
          </div>
          <div class="">
            <label>Date obtention diplome:</label> <br />
            <input
              type="date"
              onChange={(e) => setV_date_obtention(e.target.value)}
              name="v_date_obtention"
              className="form-control"
              value={v_date_obtention}
              required
              placeholder=""
            />
          </div>
          <div class="">
            <label>Activité:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_en_activite(e.target.value)}
              name="v_en_activite"
              className="form-control"
              value={v_en_activite}
              required
              placeholder=""
            />
          </div>
          <div class="">
            <label>Sécteur d'activité:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_en_secteur(e.target.value)}
              name="v_en_secteur"
              className="form-control"
              value={v_en_secteur}
              required
              placeholder="dossier"
            />
          </div>
          <div class="">
            <label>Domaine:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_domaine(e.target.value)}
              name="v_domaine"
              className="form-control"
              value={v_domaine}
              required
              placeholder="domaine"
            />
          </div>
          <div class="">
            <label>Etablissement accueil:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_etab_accueil(e.target.value)}
              name="v_etab_accueil"
              className="form-control"
              value={v_etab_accueil}
              required
              placeholder="etab_accueil"
            />
          </div>
          <div class="">
            <label>Domaine:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_domaine(e.target.value)}
              name="v_domaine"
              className="form-control"
              value={v_domaine}
              required
              placeholder="domaine"
            />
          </div>
          <div class="">
            <label>Lieu:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_lieu(e.target.value)}
              name="v_lieu"
              className="form-control"
              value={v_lieu}
              required
              placeholder="Lieu"
            />
          </div>
          <div class="">
            <label>filière:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_filiere(e.target.value)}
              name="v_filiere"
              className="form-control"
              value={v_filiere}
              required
              placeholder="filiere"
            />
          </div>
          <div class="">
            <label>Specialisation:</label> <br />
            <input
              type="text"
              onChange={(e) => setV_specialisation(e.target.value)}
              name="v_specialisation"
              className="form-control"
              value={v_specialisation}
              required
              placeholder="specialisation"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleEdit(v_id_demande)}
          >
            Modifier
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ background: "#" }} closeButton>
          Ajout demande
        </Modal.Header>
        <Modal.Body>
          {/* <div class="">
            <label>id demandeur :</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="v_demandeurIdDemandeur"
              value={values.demandeurIdDemandeur}
              className="form-control"
              required
              placeholder="id demandeur"
            />
          </div> */}
          <div class="">
            <label>Etablissement:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="etablissement"
              value={values.etablissement}
              className="form-control"
              required
              placeholder="Etablissement"
            />
          </div>
          <div class="">
            <label>Objecif:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="objecif"
              value={values.objecif}
              className="form-control"
              required
              placeholder="Objectif"
            />
          </div>
          <div class="">
            <label>Duree stage:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="duree"
              value={values.duree}
              className="form-control"
              required
              placeholder="durée"
            />
          </div>
          <div class="">
            <label>Diplôme:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="diplome"
              className="form-control"
              required
              value={values.diplome}
            />
          </div>
          <div class="">
            <label>Date obtention diplome:</label> <br />
            <input
              type="date"
              onChange={handleInput}
              name="date_obtention"
              className="form-control"
              value={values.date_obtention}
              required
              placeholder=""
            />
          </div>
          <div class="">
            <label>Activité:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="en_activite"
              className="form-control"
              value={values.en_activite}
              required
              placeholder=""
            />
          </div>
          <div class="">
            <label>Sécteur d'activité:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="v_en_secteur"
              className="form-control"
              value={values.en_secteur}
              required
              placeholder="dossier"
            />
          </div>
          <div class="">
            <label>Domaine:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="v_domaine"
              className="form-control"
              value={values.domaine}
              required
              placeholder="domaine"
            />
          </div>
          <div class="">
            <label>Etablissement accueil:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="etab_accueil"
              className="form-control"
              value={values.etab_accueil}
              required
              placeholder="etab_accueil"
            />
          </div>
          <div class="">
            <label>Domaine:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="domaine"
              className="form-control"
              value={values.domaine}
              required
              placeholder="domaine"
            />
          </div>
          <div class="">
            <label>Lieu:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="lieu"
              className="form-control"
              value={values.lieu}
              required
              placeholder="Lieu"
            />
          </div>
          <div class="">
            <label>filière:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="filiere"
              className="form-control"
              value={values.filiere}
              required
              placeholder="filiere"
            />
          </div>
          <div class="">
            <label>Specialisation:</label> <br />
            <input
              type="text"
              onChange={handleInput}
              name="v_specialisation"
              className="form-control"
              value={values.specialisation}
              required
              placeholder="specialisation"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleAdd()}
          >
            Ajouter
          </button>
        </Modal.Footer>
      </Modal>
      <button className="btn btn-success" onClick={(e) => setShow(true)}>
        +
      </button>
    </div>
  );
}

export default ContenuOffre;
