import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/offre_demande.css";
import data from "./data.js";
// import Card from "./Card.js"
import { AiOutlineCheck } from "react-icons/ai";

function Offre_demande() {
  const [currentPage, setCurrentPage] = useState(1);
  const recordPage = 6;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const records = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / recordPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  return (
    <>
      <div className="container">
        <div class="row">
          {records.map((item, id) => (
            // <Card item={item}/>

            <div className="sous_container col" key={id}>
              <div class="entete">
                <div class="date">
                  <span>{item.date}</span>
                </div>
                <div class="entete_droit">
                  <h4 class="nom_auteur">{item.nom}</h4>
                  <h5 class="categorie_offre">{item.categorie}</h5>
                </div>
              </div>
              <p class="contenu">{item.contenu}</p>
              <div class="footer">
                <button class="voir_plus">Voir plus</button>
                <button class="postuler">
                  Postuler
                  <span>
                    <AiOutlineCheck />
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination justify-content-center mt-4 ">
        <button className="page-link" onClick={prePage}>
          Precedent
        </button>
        {numbers.map((item, id) => (
          <button
            className={`page-item ${
              currentPage === item ? "active" : ""
            } page-link`}
            key={id}
            onClick={() => changePage(item)}
          >
            {item}
          </button>
        ))}
        <button className="page-link" onClick={nextPage}>
          Suivant
        </button>
      </div>
    </>
  );
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changePage(id) {
    setCurrentPage(id);
  }
}

export default Offre_demande;
