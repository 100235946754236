import React from "react";
import "../css/Apropos.css";
import Footer from "../../layout/js/footer";
import NavBar from "../../layout/js/nav_bar";
const Apropos = () => {
  return (
    <>
      <NavBar />
      <div className="container">
        <h1
          className="text-center"
          style={{
            marginTop: "6%",

            width: "70%",
            marginLeft: "15%",
          }}
        >
          A propos de nous
        </h1>
        <br />
        <div className="row">
          <div className="col-md-6">
            <p>
              Traikefa'2asa est bien plus qu'une simple plateforme d'emploi à
              Madagascar. C'est un véritable tremplin vers un avenir
              professionnel prometteur. Notre mission est de mettre en relation
              les demandeurs de stages et les prestataires, de faciliter la
              recherche de talents par les entreprises et d'offrir des
              opportunités de développement de carrière aux jeunes talents
              ambitieux. Nous croyons fermement à l'importance de
              l'employabilité et du développement des compétences pour le
              développement personnel et professionnel. Notre équipe dévouée
              s'engage à offrir une expérience utilisateur exceptionnelle, des
              opportunités de stages de qualité et des services sur mesure pour
              soutenir la réussite professionnelle de chacun.
            </p>
          </div>
          <div className="flex-item1 col-md-6">
            <h6 className="text-center">
              Téléphone : 034 22 555 55 / 032 55 555 55
            </h6>
            <h6 className="text-center">
              Email : traikefa2asa@traikefa2asa.mg
            </h6>
            <h6 className="text-center">
              Adresse : Andavamanba Tana Renivohitra 101 Madagasikara
            </h6>
            <h6 className="text-center">Site Web : www.eam.mg</h6>
            <h6 className="text-center">Site Web : www.traikef2asa.mg</h6>
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className="text-2xl text-center ">Nous contacter</h1>
        <p className="text-center">
          Vous pouvez directement nous contacter pour obtenir plus d’information
        </p>
        <form className="form-control">
          <div className="row mb-3">
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                placeholder="Votre nom"
              />
            </div>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                placeholder="Votre numéro"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                placeholder="Votre Adresse email"
              />
            </div>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                placeholder="Objet de votre message"
              />
            </div>
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              placeholder="Entrer votre message ici"
              rows="4"
            ></textarea>
          </div>
        </form>
        <br />
        <div class="flex flex-row-reverse">
          <span className="justify-content-right">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
              Envoyer
            </button>
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Apropos;
